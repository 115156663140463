/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * * `debit` - Debit
 * * `credit` - Credit
 */
export enum AccountingTypeEnum {
  Debit = 'debit',
  Credit = 'credit',
}

export interface ActServiceData {
  serviceName: string;
  quantity: number;
  price: string;
  amount: string;
}

/**
 * * `archive` - archive
 * * `unarchive` - unarchive
 */
export enum ActionEnum {
  Archive = 'archive',
  Unarchive = 'unarchive',
}

export interface Agent {
  /** @format uuid */
  readonly id: string;
  organization: Organization;
}

export interface AttachedDocumentTag {
  /** @format uuid */
  id: string;
  name: string;
  /**
   * * `COFEE` - Coffee
   * * `CORAL` - Coral
   * * `ORANGE` - Orange
   * * `YELLOW` - Yellow
   * * `OLIVE` - Olive
   * * `FOREST_GREEN` - Forest Green
   * * `TEAL` - Teal
   * * `STEEL_BLUE` - Steel Blue
   * * `MEDIUM_BLUE` - Medium Blue
   * * `NAVY` - Navy
   * * `DARK_VIOLET` - Dark Violet
   * * `PURPLE` - Purple
   * * `DEEP_PINK` - Deep Pink
   * * `RED` - Red
   * * `FIRE_BRICK` - Fire Brick
   */
  color: ColorEnum;
}

export interface AttachedDocumentTagRequest {
  /** @format uuid */
  id: string;
  /** @minLength 1 */
  name: string;
  /**
   * * `COFEE` - Coffee
   * * `CORAL` - Coral
   * * `ORANGE` - Orange
   * * `YELLOW` - Yellow
   * * `OLIVE` - Olive
   * * `FOREST_GREEN` - Forest Green
   * * `TEAL` - Teal
   * * `STEEL_BLUE` - Steel Blue
   * * `MEDIUM_BLUE` - Medium Blue
   * * `NAVY` - Navy
   * * `DARK_VIOLET` - Dark Violet
   * * `PURPLE` - Purple
   * * `DEEP_PINK` - Deep Pink
   * * `RED` - Red
   * * `FIRE_BRICK` - Fire Brick
   */
  color: ColorEnum;
}

export interface AttachedDocumentTagSetRequest {
  tagIds: string[];
}

export interface BillOfLadingData {
  /** @maxLength 63 */
  blNumber: string;
}

export interface BillOfLadingDataRequest {
  /** @maxLength 63 */
  blNumber: string;
}

export enum BlankEnum {
  Value = '',
}

/**
 * * `orderRef` - orderRef
 * * `clientRef` - clientRef
 * * `fromCountry` - fromCountry
 * * `toCountry` - toCountry
 * * `consignorName` - consignorName
 * * `consignorAddress` - consignorAddress
 * * `consigneeName` - consigneeName
 * * `consigneeAddress` - consigneeAddress
 * * `termsOfDelivery` - termsOfDelivery
 * * `shipmentUnits` - shipmentUnits
 */
export enum BlankFieldEnum {
  OrderRef = 'orderRef',
  ClientRef = 'clientRef',
  FromCountry = 'fromCountry',
  ToCountry = 'toCountry',
  ConsignorName = 'consignorName',
  ConsignorAddress = 'consignorAddress',
  ConsigneeName = 'consigneeName',
  ConsigneeAddress = 'consigneeAddress',
  TermsOfDelivery = 'termsOfDelivery',
  ShipmentUnits = 'shipmentUnits',
}

export interface Charge {
  /** @format uuid */
  readonly id: string;
  order: OrderCharge;
  service: Service;
  /** @format uuid */
  transaction: string;
  /**
   * Should usually come from the service description but may be overridden
   * @maxLength 255
   */
  description: string;
  /**
   * @min 1
   * @max 2147483647
   */
  quantity: number;
  /**
   * The initial price offered for the service. The final price may vary if additional fees are applied.
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  price: string;
  /**
   * Currency of the charge.
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  chargeCurrency: CurrencyEnum;
  /**
   * Rate to convert charge currency to base currency.
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,6})?$
   */
  baseCurrencyRate: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  amountInInvoiceCurrency: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  vat: string;
}

export interface ChargeCreateRequest {
  /** @format uuid */
  order: string;
  /** @format uuid */
  service: string;
  /**
   * Should usually come from the service description but may be overridden
   * @minLength 1
   * @maxLength 255
   */
  description?: string;
  /**
   * @min 1
   * @max 2147483647
   */
  quantity: number;
  /**
   * The initial price offered for the service. The final price may vary if additional fees are applied.
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  price: string;
  /**
   * Rate to convert charge currency to base currency.
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,6})?$
   */
  baseCurrencyRate: string;
  /**
   * Currency of the charge.
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  chargeCurrency: CurrencyEnum;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  vat: string;
}

export interface Client {
  /** @format uuid */
  readonly id: string;
  organization: Organization;
}

export interface CmrData {
  /** @maxLength 63 */
  cmr: string;
}

export interface CmrDataRequest {
  /** @maxLength 63 */
  cmr: string;
}

/**
 * * `COFEE` - Coffee
 * * `CORAL` - Coral
 * * `ORANGE` - Orange
 * * `YELLOW` - Yellow
 * * `OLIVE` - Olive
 * * `FOREST_GREEN` - Forest Green
 * * `TEAL` - Teal
 * * `STEEL_BLUE` - Steel Blue
 * * `MEDIUM_BLUE` - Medium Blue
 * * `NAVY` - Navy
 * * `DARK_VIOLET` - Dark Violet
 * * `PURPLE` - Purple
 * * `DEEP_PINK` - Deep Pink
 * * `RED` - Red
 * * `FIRE_BRICK` - Fire Brick
 */
export enum ColorEnum {
  COFEE = 'COFEE',
  CORAL = 'CORAL',
  ORANGE = 'ORANGE',
  YELLOW = 'YELLOW',
  OLIVE = 'OLIVE',
  FOREST_GREEN = 'FOREST_GREEN',
  TEAL = 'TEAL',
  STEEL_BLUE = 'STEEL_BLUE',
  MEDIUM_BLUE = 'MEDIUM_BLUE',
  NAVY = 'NAVY',
  DARK_VIOLET = 'DARK_VIOLET',
  PURPLE = 'PURPLE',
  DEEP_PINK = 'DEEP_PINK',
  RED = 'RED',
  FIRE_BRICK = 'FIRE_BRICK',
}

export interface ContainerData {
  /** @maxLength 63 */
  containerId: string;
}

export interface ContainerDataRequest {
  /** @maxLength 63 */
  containerId: string;
}

/**
 * * `AF` - Afghanistan
 * * `AL` - Albania
 * * `DZ` - Algeria
 * * `AS` - American Samoa
 * * `AD` - Andorra
 * * `AO` - Angola
 * * `AI` - Anguilla
 * * `AQ` - Antarctica
 * * `AG` - Antigua and Barbuda
 * * `AR` - Argentina
 * * `AM` - Armenia
 * * `AW` - Aruba
 * * `AU` - Australia
 * * `AT` - Austria
 * * `AZ` - Azerbaijan
 * * `BS` - Bahamas
 * * `BH` - Bahrain
 * * `BD` - Bangladesh
 * * `BB` - Barbados
 * * `BY` - Belarus
 * * `BE` - Belgium
 * * `BZ` - Belize
 * * `BJ` - Benin
 * * `BM` - Bermuda
 * * `BT` - Bhutan
 * * `BO` - Bolivia
 * * `BQ` - Caribbean Netherlands
 * * `BA` - Bosnia and Herzegovina
 * * `BW` - Botswana
 * * `BV` - Bouvet Island
 * * `BR` - Brazil
 * * `IO` - British Indian Ocean Territory
 * * `BN` - Brunei Darussalam
 * * `BG` - Bulgaria
 * * `BF` - Burkina Faso
 * * `BI` - Burundi
 * * `CV` - Cabo Verde
 * * `KH` - Cambodia
 * * `CM` - Cameroon
 * * `CA` - Canada
 * * `KY` - Cayman Islands
 * * `CF` - Central African Republic
 * * `TD` - Chad
 * * `CL` - Chile
 * * `CN` - China
 * * `CX` - Christmas Island
 * * `CC` - Cocos Islands
 * * `CO` - Colombia
 * * `KM` - Comoros
 * * `CD` - Congo
 * * `CG` - Congo
 * * `CK` - Cook Islands
 * * `CR` - Costa Rica
 * * `HR` - Croatia
 * * `CU` - Cuba
 * * `CW` - Curacao
 * * `CY` - Cyprus
 * * `CZ` - Czech Republic
 * * `CI` - Cote d'Ivoire
 * * `DK` - Denmark
 * * `DJ` - Djibouti
 * * `DM` - Dominica
 * * `DO` - Dominican Republic
 * * `EC` - Ecuador
 * * `EG` - Egypt
 * * `SV` - El Salvador
 * * `GQ` - Equatorial Guinea
 * * `ER` - Eritrea
 * * `EE` - Estonia
 * * `SZ` - Eswatini
 * * `ET` - Ethiopia
 * * `FK` - Falkland Islands
 * * `FO` - Faroe Islands
 * * `FJ` - Fiji
 * * `FI` - Finland
 * * `FR` - France
 * * `GF` - French Guiana
 * * `PF` - French Polynesia
 * * `TF` - French Southern Territories
 * * `GA` - Gabon
 * * `GM` - Gambia
 * * `GE` - Georgia
 * * `DE` - Germany
 * * `GH` - Ghana
 * * `GI` - Gibraltar
 * * `GR` - Greece
 * * `GL` - Greenland
 * * `GD` - Grenada
 * * `GP` - Guadeloupe
 * * `GU` - Guam
 * * `GT` - Guatemala
 * * `GG` - Guernsey
 * * `GN` - Guinea
 * * `GW` - Guinea-Bissau
 * * `GY` - Guyana
 * * `HT` - Haiti
 * * `HM` - Heard Island and McDonald Islands
 * * `VA` - Holy See
 * * `HN` - Honduras
 * * `HK` - Hong Kong
 * * `HU` - Hungary
 * * `IS` - Iceland
 * * `IN` - India
 * * `ID` - Indonesia
 * * `IR` - Iran
 * * `IQ` - Iraq
 * * `IE` - Ireland
 * * `IM` - Isle of Man
 * * `IL` - Israel
 * * `IT` - Italy
 * * `JM` - Jamaica
 * * `JP` - Japan
 * * `JE` - Jersey
 * * `JO` - Jordan
 * * `KZ` - Kazakhstan
 * * `KE` - Kenya
 * * `KI` - Kiribati
 * * `KP` - North Korea
 * * `KR` - South Korea
 * * `KW` - Kuwait
 * * `KG` - Kyrgyzstan
 * * `LA` - Laos
 * * `LV` - Latvia
 * * `LB` - Lebanon
 * * `LS` - Lesotho
 * * `LR` - Liberia
 * * `LY` - Libya
 * * `LI` - Liechtenstein
 * * `LT` - Lithuania
 * * `LU` - Luxembourg
 * * `MO` - Macao
 * * `MG` - Madagascar
 * * `MW` - Malawi
 * * `MY` - Malaysia
 * * `MV` - Maldives
 * * `ML` - Mali
 * * `MT` - Malta
 * * `MH` - Marshall Islands
 * * `MQ` - Martinique
 * * `MR` - Mauritania
 * * `MU` - Mauritius
 * * `YT` - Mayotte
 * * `MX` - Mexico
 * * `FM` - Micronesia
 * * `MD` - Moldova
 * * `MC` - Monaco
 * * `MN` - Mongolia
 * * `ME` - Montenegro
 * * `MS` - Montserrat
 * * `MA` - Morocco
 * * `MZ` - Mozambique
 * * `MM` - Myanmar
 * * `NA` - Namibia
 * * `NR` - Nauru
 * * `NP` - Nepal
 * * `NL` - Netherlands
 * * `NC` - New Caledonia
 * * `NZ` - New Zealand
 * * `NI` - Nicaragua
 * * `NE` - Niger
 * * `NG` - Nigeria
 * * `NU` - Niue
 * * `NF` - Norfolk Island
 * * `MP` - Northern Mariana Islands
 * * `NO` - Norway
 * * `OM` - Oman
 * * `PK` - Pakistan
 * * `PW` - Palau
 * * `PS` - Palestine, State of
 * * `PA` - Panama
 * * `PG` - Papua New Guinea
 * * `PY` - Paraguay
 * * `PE` - Peru
 * * `PH` - Philippines
 * * `PN` - Pitcairn
 * * `PL` - Poland
 * * `PT` - Portugal
 * * `PR` - Puerto Rico
 * * `QA` - Qatar
 * * `MK` - Republic of North Macedonia
 * * `RO` - Romania
 * * `RU` - Russian Federation
 * * `RW` - Rwanda
 * * `RE` - Reunion
 * * `BL` - Saint Barthelemy
 * * `SH` - Saint Helena, Ascension and Tristan da Cunha
 * * `KN` - Saint Kitts and Nevis
 * * `LC` - Saint Lucia
 * * `MF` - Saint Martin (French part)
 * * `PM` - Saint Pierre and Miquelon
 * * `VC` - Saint Vincent and the Grenadines
 * * `WS` - Samoa
 * * `SM` - San Marino
 * * `ST` - Sao Tome and Principe
 * * `SA` - Saudi Arabia
 * * `SN` - Senegal
 * * `RS` - Serbia
 * * `SC` - Seychelles
 * * `SL` - Sierra Leone
 * * `SG` - Singapore
 * * `SX` - Sint Maarten (Dutch part)
 * * `SK` - Slovakia
 * * `SI` - Slovenia
 * * `SB` - Solomon Islands
 * * `SO` - Somalia
 * * `ZA` - South Africa
 * * `GS` - Sandwich Islands
 * * `SS` - South Sudan
 * * `ES` - Spain
 * * `LK` - Sri Lanka
 * * `SD` - Sudan
 * * `SR` - Suriname
 * * `SJ` - Svalbard and Jan Mayen
 * * `SE` - Sweden
 * * `CH` - Switzerland
 * * `SY` - Syrian Arab Republic
 * * `TW` - Taiwan
 * * `TJ` - Tajikistan
 * * `TZ` - Tanzania
 * * `TH` - Thailand
 * * `TL` - East Timor
 * * `TG` - Togo
 * * `TK` - Tokelau
 * * `TO` - Tonga
 * * `TT` - Trinidad and Tobago
 * * `TN` - Tunisia
 * * `TR` - Turkey
 * * `TM` - Turkmenistan
 * * `TC` - Turks and Caicos Islands
 * * `TV` - Tuvalu
 * * `UG` - Uganda
 * * `UA` - Ukraine
 * * `AE` - United Arab Emirates
 * * `GB` - United Kingdom
 * * `UM` - Minor Outlying Islands (USA)
 * * `US` - United States
 * * `UY` - Uruguay
 * * `UZ` - Uzbekistan
 * * `VU` - Vanuatu
 * * `VE` - Venezuela
 * * `VN` - Vietnam
 * * `VG` - Virgin Islands (UK)
 * * `VI` - Virgin Islands (USA)
 * * `WF` - Wallis and Futuna
 * * `EH` - Western Sahara
 * * `YE` - Yemen
 * * `ZM` - Zambia
 * * `ZW` - Zimbabwe
 * * `AX` - Aland Islands
 */
export enum CountryEnum {
  AF = 'AF',
  AL = 'AL',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BQ = 'BQ',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  IO = 'IO',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  CV = 'CV',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  KY = 'KY',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CX = 'CX',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CD = 'CD',
  CG = 'CG',
  CK = 'CK',
  CR = 'CR',
  HR = 'HR',
  CU = 'CU',
  CW = 'CW',
  CY = 'CY',
  CZ = 'CZ',
  CI = 'CI',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  SZ = 'SZ',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  TF = 'TF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  VA = 'VA',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  MP = 'MP',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  MK = 'MK',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  RE = 'RE',
  BL = 'BL',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  MF = 'MF',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SX = 'SX',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  GS = 'GS',
  SS = 'SS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TW = 'TW',
  TJ = 'TJ',
  TZ = 'TZ',
  TH = 'TH',
  TL = 'TL',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  GB = 'GB',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  VG = 'VG',
  VI = 'VI',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW',
  AX = 'AX',
}

export interface CountryNamesByCode {
  countries: Record<string, string>;
}

/** Currency */
export enum Currency {
  AED = 'AED',
  AUD = 'AUD',
  CHF = 'CHF',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  TRY = 'TRY',
  USD = 'USD',
  RUB = 'RUB',
}

/**
 * * `AED` - United Arab Emirates Dirham
 * * `AUD` - Australian Dollar
 * * `CHF` - Swiss Franc
 * * `CNY` - Chinese Yuan
 * * `EUR` - Euro
 * * `GBP` - British Pound Sterling
 * * `TRY` - Turkish Lira
 * * `USD` - United States Dollar
 * * `RUB` - Russian Ruble
 */
export enum CurrencyEnum {
  AED = 'AED',
  AUD = 'AUD',
  CHF = 'CHF',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  TRY = 'TRY',
  USD = 'USD',
  RUB = 'RUB',
}

/** CurrencyHistoricalRatesDto */
export interface CurrencyHistoricalRatesDto {
  base: Currency;
  /** Timestamp */
  timestamp: number;
  /** Rates */
  rates: Record<string, number | string>;
}

export interface Department {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 255 */
  name: string;
}

/**
 * * `from` - From
 * * `via` - Via
 * * `to` - To
 */
export enum DirectionEnum {
  From = 'from',
  Via = 'via',
  To = 'to',
}

/**
 * * `order_ref` - order_ref
 * * `client_ref` - client_ref
 * * `from_country` - from_country
 * * `to_country` - to_country
 * * `consignor_name` - consignor_name
 * * `consignor_address` - consignor_address
 * * `consignee_name` - consignee_name
 * * `consignee_address` - consignee_address
 * * `terms_of_delivery` - terms_of_delivery
 * * `shipment_units` - shipment_units
 */
export enum FieldNameEnum {
  OrderRef = 'order_ref',
  ClientRef = 'client_ref',
  FromCountry = 'from_country',
  ToCountry = 'to_country',
  ConsignorName = 'consignor_name',
  ConsignorAddress = 'consignor_address',
  ConsigneeName = 'consignee_name',
  ConsigneeAddress = 'consignee_address',
  TermsOfDelivery = 'terms_of_delivery',
  ShipmentUnits = 'shipment_units',
}

/**
 * * `1` - First
 * * `2` - Second
 * * `3` - Third
 */
export enum GroupNumberEnum {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface HouseBillOfLadingData {
  /** @maxLength 63 */
  hawb: string;
  /** @maxLength 63 */
  mawb: string;
}

export interface HouseBillOfLadingDataRequest {
  /** @maxLength 63 */
  hawb: string;
  /** @maxLength 63 */
  mawb: string;
}

export interface Insurance {
  /** @format uuid */
  readonly id: string;
  /**
   * How the insurance was created and is being updated: manually by the user or via API calls
   *
   * * `api` - API
   * * `manual` - Manual
   */
  readonly updateMethod: InsuranceUpdateMethodEnum;
  /**
   * * `new` - New
   * * `approved` - Approved
   * * `declined` - Declined
   */
  status?: InsuranceStatusEnum;
  /** @maxLength 127 */
  number: string;
  /**
   * The currency of the insurance
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  currency?: CurrencyEnum;
  /**
   * The maximum amount that the insurance company will pay for a covered case
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  coverageAmount?: string;
  /**
   * The amount that the policyholder paid to the insurance company for coverage
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  premium?: string;
  additionalInformation: string;
  readonly insuranceDocument: InsuranceDocument | null;
}

export interface InsuranceCreateRequest {
  /** * `manual` - manual */
  updateMethod: InsuranceCreateUpdateMethodEnum;
}

/** * `manual` - manual */
export enum InsuranceCreateUpdateMethodEnum {
  Manual = 'manual',
}

export interface InsuranceDocument {
  /** @format date-time */
  readonly created: string;
  /** @format uri */
  document: string;
  /** @format uri */
  readonly documentDownloadable: string;
  readonly name: string;
  /** Document extension */
  readonly extension: string;
}

export interface InsuranceDocumentRequest {
  /** @format binary */
  document: File;
}

export interface InsuranceRequest {
  /**
   * * `new` - New
   * * `approved` - Approved
   * * `declined` - Declined
   */
  status?: InsuranceStatusEnum;
  /**
   * @minLength 1
   * @maxLength 127
   */
  number: string;
  /**
   * The currency of the insurance
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  currency?: CurrencyEnum;
  /**
   * The maximum amount that the insurance company will pay for a covered case
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  coverageAmount?: string;
  /**
   * The amount that the policyholder paid to the insurance company for coverage
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  premium?: string;
  /** @minLength 1 */
  additionalInformation: string;
}

/**
 * * `new` - New
 * * `approved` - Approved
 * * `declined` - Declined
 */
export enum InsuranceStatusEnum {
  New = 'new',
  Approved = 'approved',
  Declined = 'declined',
}

/**
 * * `api` - API
 * * `manual` - Manual
 */
export enum InsuranceUpdateMethodEnum {
  Api = 'api',
  Manual = 'manual',
}

export interface InvoiceDocument {
  /** @format date-time */
  readonly created: string;
  /** @format uri */
  document: string;
  /** @format uri */
  documentDownloadable: string;
  /** @maxLength 255 */
  name: string;
  /** @maxLength 10 */
  extension: string;
}

export interface InvoiceDocumentCreate {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created: string;
  /** @format uri */
  document: string;
  /** @format uri */
  readonly documentDownloadable: string;
  readonly name: string;
  readonly extension: string;
}

export interface InvoiceDocumentCreateRequest {
  /** @format binary */
  document: File;
}

export interface InvoiceList {
  /** @format uuid */
  readonly id: string;
  /**
   * Type of invoice: sale or purchase.
   *
   * * `sale` - Sale
   * * `purchase` - Purchase
   */
  invoiceType: InvoiceTypeEnum;
  /** @maxLength 50 */
  number: string;
  /**
   * * `open` - Open
   * * `closed` - Closed
   */
  status?: InvoiceStatusEnum;
  /**
   * Date of the billing calculation and currency convertation.
   * @format date
   */
  invoicingDate: string;
  order: OrderInvoice;
  readonly counterparty: string;
  readonly transportationMethods: any[];
  readonly chargesTotalSumInBaseCurrency: string;
}

/**
 * Needed for the list view in order, both for sale and purchase.
 * Note that total sum is not transaction sum, but the sum of all charges for this order
 */
export interface InvoiceListForOrder {
  /** @format uuid */
  readonly id: string;
  /**
   * Type of invoice: sale or purchase.
   *
   * * `sale` - Sale
   * * `purchase` - Purchase
   */
  invoiceType: InvoiceTypeEnum;
  /** @maxLength 50 */
  number: string;
  /**
   * * `open` - Open
   * * `closed` - Closed
   */
  status?: InvoiceStatusEnum;
  /**
   * The basis order for which invoice is related (is created from).
   * @format uuid
   */
  order: string;
  readonly orderRef: string;
  /**
   * Date of the billing calculation and currency convertation.
   * @format date
   */
  invoicingDate: string;
  readonly orderChargesTotalSumInInvoiceCurrency: string;
  readonly orderChargesOverallTotalSumInInvoiceCurrency: string;
  readonly baseCurrency: string;
  /**
   * Currency of total sum in invoice.
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  invoiceCurrency?: CurrencyEnum;
  readonly invoiceDocument: InvoiceDocument | null;
  /** Counterparty of the invoice. Payer for sales, receiver for purchases. */
  readonly counterparty: OrganizationSimple;
}

export interface InvoiceListTotalNet {
  salesNet: string;
  purchasesNet: string;
  totalNet: string;
  /** Khareba Turizm */
  cargoTrTotalNet: string;
}

/**
 * * `open` - Open
 * * `closed` - Closed
 */
export enum InvoiceStatusEnum {
  Open = 'open',
  Closed = 'closed',
}

/**
 * * `sale` - Sale
 * * `purchase` - Purchase
 */
export enum InvoiceTypeEnum {
  Sale = 'sale',
  Purchase = 'purchase',
}

/**
 * Serializer class used to validate a username and password.
 *
 * 'username' is identified by the custom UserModel.USERNAME_FIELD.
 *
 * Returns a JSON Web Token that can be used to authenticate later calls.
 */
export interface JSONWebToken {
  readonly token: string;
}

/**
 * Serializer class used to validate a username and password.
 *
 * 'username' is identified by the custom UserModel.USERNAME_FIELD.
 *
 * Returns a JSON Web Token that can be used to authenticate later calls.
 */
export interface JSONWebTokenRequest {
  /** @minLength 1 */
  password: string;
  /** @minLength 1 */
  username: string;
}

export interface Label {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 63 */
  name: string;
  /**
   * * `COFEE` - Coffee
   * * `CORAL` - Coral
   * * `ORANGE` - Orange
   * * `YELLOW` - Yellow
   * * `OLIVE` - Olive
   * * `FOREST_GREEN` - Forest Green
   * * `TEAL` - Teal
   * * `STEEL_BLUE` - Steel Blue
   * * `MEDIUM_BLUE` - Medium Blue
   * * `NAVY` - Navy
   * * `DARK_VIOLET` - Dark Violet
   * * `PURPLE` - Purple
   * * `DEEP_PINK` - Deep Pink
   * * `RED` - Red
   * * `FIRE_BRICK` - Fire Brick
   */
  color: ColorEnum;
}

/**
 * we've decided to use charfield for url because of:
 * - complexity url validation errors handling on frontend when there a lot of links in one query.
 * - that is doesnt so matter for business.
 */
export interface Link {
  /** @maxLength 127 */
  title: string;
  url: string;
}

export interface LinkGroup {
  /**
   * * `1` - First
   * * `2` - Second
   * * `3` - Third
   */
  groupNumber: GroupNumberEnum;
  links: Link[];
}

export interface LinkGroupRequest {
  /**
   * * `1` - First
   * * `2` - Second
   * * `3` - Third
   */
  groupNumber: GroupNumberEnum;
  links: LinkRequest[];
}

/**
 * we've decided to use charfield for url because of:
 * - complexity url validation errors handling on frontend when there a lot of links in one query.
 * - that is doesnt so matter for business.
 */
export interface LinkRequest {
  /**
   * @minLength 1
   * @maxLength 127
   */
  title: string;
  /** @minLength 1 */
  url: string;
}

/**
 * * `not-set` - Not set
 * * `FCL` - Full Container Load
 * * `LCL` - Less than Container Load
 */
export enum LoadTypeEnum {
  NotSet = 'not-set',
  FCL = 'FCL',
  LCL = 'LCL',
}

export interface MasterBillOfLadingData {
  /** @maxLength 63 */
  mawb: string;
}

export interface MasterBillOfLadingDataRequest {
  /** @maxLength 63 */
  mawb: string;
}

export type MetaSiteNotificationContext = OrderContextDto | TaskContextDto;

/** Simple Task serializer to show in Order list. Remove this field when notifications bell is implemented. */
export interface NotCompletedTaskInOrder {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 512 */
  description: string;
  /** @format date-time */
  dueDate: string;
}

/**
 * * `order` - order
 * * `task` - task
 */
export enum NotificationTypeEnum {
  Order = 'order',
  Task = 'task',
}

export enum NotifyPeriodEnum {
  Value7 = 7,
}

export type NullEnum = null;

export interface OrderAgent {
  /** @format uuid */
  readonly id: string;
  agent: Agent;
  /** @format date */
  bidDate?: string | null;
  priceConfirmed?: boolean;
  agentPrice?: string;
  sellingPrice?: string;
}

export interface OrderAgentCreateRequest {
  /** @format uuid */
  agent: string;
  /** @format date */
  bidDate?: string | null;
  priceConfirmed?: boolean;
  agentPrice?: string;
  sellingPrice?: string;
}

export interface OrderAgentSimple {
  agent: Agent;
}

export interface OrderArchiveRequest {
  /**
   * * `archive` - archive
   * * `unarchive` - unarchive
   */
  action: ActionEnum;
}

export interface OrderArchiveResponse {
  ok: boolean;
}

export interface OrderCharge {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 255 */
  name: string;
  /**
   * Human convenient ref to order
   * @maxLength 127
   */
  orderRef?: string;
}

/** OrderContextDto */
export interface OrderContextDto {
  /**
   * Order Id
   * @format uuid
   */
  orderId: string;
  /** Order Ref */
  orderRef: string;
  /** Order Name */
  orderName: string;
}

export interface OrderCreateRequest {
  /** @maxLength 255 */
  name?: string;
  /**
   * User defined creation date
   * @format date
   */
  creationDate?: string;
  /** @format uuid */
  client: string;
  /**
   * Reference to the client's external system
   * @maxLength 127
   */
  clientRef?: string;
  /**
   * * `TRA` - Transit
   * * `INV` - Invoicing
   * * `TRB` - Transit SPB office
   * * `COF` - Cargo
   * * `INVSPB` - Invoicing SPB Office
   */
  shipmentSerie: ShipmentSerieEnum;
  /**
   * * `not-set` - Not set
   * * `FCL` - Full Container Load
   * * `LCL` - Less than Container Load
   */
  loadType?: LoadTypeEnum;
  securityCheckRequired?: boolean;
  insuranceCheckRequired?: boolean;
  expensiveCargo?: boolean;
  comment?: string;
  destinations?: OrderDestinationCreateRequest[];
  transportationMethods?: TransportationMethodsEnum[];
  labels?: string[];
  /** @format uuid */
  agent?: string;
  consignorName?: string;
  consignorAddress?: string;
  consigneeName?: string;
  consigneeAddress?: string;
  termsOfDelivery?: TermsOfDeliveryEnum | BlankEnum;
  /** @maxLength 255 */
  termsOfDeliveryCity?: string;
}

export interface OrderDestination {
  /**
   * * `from` - From
   * * `via` - Via
   * * `to` - To
   */
  direction: DirectionEnum;
  /**
   * ISO 3166-1 alpha-2 country code
   *
   * * `AF` - Afghanistan
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Caribbean Netherlands
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei Darussalam
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CD` - Congo
   * * `CG` - Congo
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curacao
   * * `CY` - Cyprus
   * * `CZ` - Czech Republic
   * * `CI` - Cote d'Ivoire
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KP` - North Korea
   * * `KR` - South Korea
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `MK` - Republic of North Macedonia
   * * `RO` - Romania
   * * `RU` - Russian Federation
   * * `RW` - Rwanda
   * * `RE` - Reunion
   * * `BL` - Saint Barthelemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - Sandwich Islands
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syrian Arab Republic
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - East Timor
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Turkey
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - Minor Outlying Islands (USA)
   * * `US` - United States
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (UK)
   * * `VI` - Virgin Islands (USA)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   * * `AX` - Aland Islands
   */
  countryCode: CountryEnum;
}

export interface OrderDestinationCreateRequest {
  /**
   * * `from` - From
   * * `via` - Via
   * * `to` - To
   */
  direction: DirectionEnum;
  /**
   * * `AF` - Afghanistan
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Caribbean Netherlands
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei Darussalam
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CD` - Congo
   * * `CG` - Congo
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curacao
   * * `CY` - Cyprus
   * * `CZ` - Czech Republic
   * * `CI` - Cote d'Ivoire
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KP` - North Korea
   * * `KR` - South Korea
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `MK` - Republic of North Macedonia
   * * `RO` - Romania
   * * `RU` - Russian Federation
   * * `RW` - Rwanda
   * * `RE` - Reunion
   * * `BL` - Saint Barthelemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - Sandwich Islands
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syrian Arab Republic
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - East Timor
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Turkey
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - Minor Outlying Islands (USA)
   * * `US` - United States
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (UK)
   * * `VI` - Virgin Islands (USA)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   * * `AX` - Aland Islands
   */
  countryCode: CountryEnum;
}

export interface OrderDetailed {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 255 */
  name: string;
  archived?: boolean;
  readonly client: Client;
  readonly pinned: boolean;
  /**
   * User defined creation date
   * @format date
   */
  creationDate?: string;
  /**
   * Reference to the client's external system
   * @maxLength 127
   */
  clientRef: string;
  /**
   * * `TRA` - Transit
   * * `INV` - Invoicing
   * * `TRB` - Transit SPB office
   * * `COF` - Cargo
   * * `INVSPB` - Invoicing SPB Office
   */
  shipmentSerie: ShipmentSerieEnum;
  /**
   * Human convenient ref to order
   * @maxLength 127
   */
  orderRef?: string;
  readonly managedBy: OrderManagerAsUser[];
  transportationMethods?: TransportationMethodsEnum[];
  readonly labels: OrderLabel[];
  readonly destinations: OrderDestination[];
  /**
   * * `not-set` - Not set
   * * `FCL` - Full Container Load
   * * `LCL` - Less than Container Load
   */
  loadType?: LoadTypeEnum;
  /** @maxLength 255 */
  consignorName?: string;
  /** @maxLength 255 */
  consigneeName?: string;
  consignorAddress?: string;
  consigneeAddress?: string;
  termsOfDelivery?: TermsOfDeliveryEnum | BlankEnum;
  /** @maxLength 255 */
  termsOfDeliveryCity?: string;
  securityCheckRequired?: boolean;
  /** @format date */
  securityCheckDate?: string | null;
  insuranceCheckRequired?: boolean;
  /** @format date */
  insuranceCheckDate?: string | null;
  expensiveCargo?: boolean;
  comment?: string;
  readonly shipmentUnitStats: OrderShipmentUnitStats;
}

export interface OrderDocument {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created: string;
  readonly name: string;
  /** Document extension */
  readonly extension: string;
  /** @format uri */
  document: string;
  /** @format uri */
  readonly documentDownloadable: string;
  readonly tags: AttachedDocumentTag[];
}

export interface OrderDocumentRequest {
  /** @format binary */
  document: File;
}

export interface OrderDocumentTag {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 63 */
  name: string;
  /**
   * * `COFEE` - Coffee
   * * `CORAL` - Coral
   * * `ORANGE` - Orange
   * * `YELLOW` - Yellow
   * * `OLIVE` - Olive
   * * `FOREST_GREEN` - Forest Green
   * * `TEAL` - Teal
   * * `STEEL_BLUE` - Steel Blue
   * * `MEDIUM_BLUE` - Medium Blue
   * * `NAVY` - Navy
   * * `DARK_VIOLET` - Dark Violet
   * * `PURPLE` - Purple
   * * `DEEP_PINK` - Deep Pink
   * * `RED` - Red
   * * `FIRE_BRICK` - Fire Brick
   */
  color: ColorEnum;
}

export interface OrderInTask {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 255 */
  name: string;
  /**
   * Human convenient ref to order
   * @maxLength 127
   */
  orderRef?: string;
}

export interface OrderInvoice {
  /** @format uuid */
  readonly id: string;
  /**
   * Human convenient ref to order
   * @maxLength 127
   */
  orderRef?: string;
  readonly serviceProvider: string;
}

export interface OrderLabel {
  /** @format uuid */
  readonly id: string;
  readonly name: string;
  readonly color: ColorEnum;
}

export interface OrderLabelSetRequest {
  labels: string[];
}

export interface OrderList {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 255 */
  name: string;
  archived?: boolean;
  readonly managedBy: OrderManagerAsUser[];
  readonly pinned: boolean;
  /**
   * User defined creation date
   * @format date
   */
  creationDate?: string;
  /**
   * Reference to the client's external system
   * @maxLength 127
   */
  clientRef: string;
  /**
   * * `TRA` - Transit
   * * `INV` - Invoicing
   * * `TRB` - Transit SPB office
   * * `COF` - Cargo
   * * `INVSPB` - Invoicing SPB Office
   */
  shipmentSerie: ShipmentSerieEnum;
  /**
   * Human convenient ref to order
   * @maxLength 127
   */
  orderRef?: string;
  transportationMethods?: TransportationMethodsEnum[];
  readonly labels: OrderLabel[];
  readonly destinations: OrderDestination[];
  securityCheckRequired?: boolean;
  /** @format date */
  securityCheckDate?: string | null;
  insuranceCheckRequired?: boolean;
  /** @format date */
  insuranceCheckDate?: string | null;
  expensiveCargo?: boolean;
  comment?: string;
  readonly orderAgents: OrderAgentSimple[];
  readonly client: Client;
  countNotCompletedTasks: number;
  readonly recentNotCompletedTasks: NotCompletedTaskInOrder[];
  readonly shipmentUnitStats: OrderShipmentUnitStats;
}

export interface OrderManagerAsUser {
  /** @format uuid */
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
}

export interface OrderManagerSetRequest {
  managers: string[];
}

export interface OrderShipmentReference {
  /** @format uuid */
  readonly id: string;
  /**
   * * `CONTAINER` - Container
   * * `VEHICLE` - Vehicle
   * * `BILL-OF-LADING` - Bill of Lading
   * * `MAWB` - Master AWB
   * * `HAWB` - House AWB
   * * `CMR` - CMR
   * * `RWB` - Railway bill
   */
  referenceType: ReferenceTypeEnum;
  referenceData: OrderShipmentReferenceData;
}

export type OrderShipmentReferenceData =
  | BillOfLadingData
  | ContainerData
  | HouseBillOfLadingData
  | MasterBillOfLadingData
  | VehicleData
  | CmrData
  | RailwayBillOfLadingData;

export type OrderShipmentReferenceDataRequest =
  | BillOfLadingDataRequest
  | ContainerDataRequest
  | HouseBillOfLadingDataRequest
  | MasterBillOfLadingDataRequest
  | VehicleDataRequest
  | CmrDataRequest
  | RailwayBillOfLadingDataRequest;

export interface OrderShipmentReferenceRequest {
  /**
   * * `CONTAINER` - Container
   * * `VEHICLE` - Vehicle
   * * `BILL-OF-LADING` - Bill of Lading
   * * `MAWB` - Master AWB
   * * `HAWB` - House AWB
   * * `CMR` - CMR
   * * `RWB` - Railway bill
   */
  referenceType: ReferenceTypeEnum;
  referenceData: OrderShipmentReferenceDataRequest;
}

export interface OrderShipmentUnit {
  /** @format uuid */
  readonly id: string;
  /**
   * Reference to the client's external system
   * @maxLength 127
   */
  clientRef?: string;
  /** @maxLength 255 */
  cargoDescription: string;
  /**
   * @min 0
   * @max 32767
   */
  quantity?: number;
  /**
   * * `CS` - Cases
   * * `PCS` - Pieces
   * * `CTN` - Carton
   * * `PLL` - Pallets
   * * `PKG` - Packages
   */
  quantityMeasure?: ShipmentUnitQuantityChoice;
  /**
   * Weight measured in kg
   * @format decimal
   * @pattern ^-?\d{0,7}(?:\.\d{0,3})?$
   */
  weight?: string;
  /**
   * Volume measured in m3
   * @format decimal
   * @pattern ^-?\d{0,7}(?:\.\d{0,3})?$
   */
  volume?: string;
  shipmentReferences: OrderShipmentReference[];
}

export interface OrderShipmentUnitCreateRequest {
  /**
   * Reference to the client's external system
   * @maxLength 127
   */
  clientRef?: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  cargoDescription: string;
  /**
   * @min 0
   * @max 32767
   */
  quantity?: number;
  /**
   * * `CS` - Cases
   * * `PCS` - Pieces
   * * `CTN` - Carton
   * * `PLL` - Pallets
   * * `PKG` - Packages
   */
  quantityMeasure?: ShipmentUnitQuantityChoice;
  /**
   * Weight measured in kg
   * @format decimal
   * @pattern ^-?\d{0,7}(?:\.\d{0,3})?$
   */
  weight?: string;
  /**
   * Volume measured in m3
   * @format decimal
   * @pattern ^-?\d{0,7}(?:\.\d{0,3})?$
   */
  volume?: string;
}

export interface OrderShipmentUnitStats {
  count: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,12}(?:\.\d{0,3})?$
   */
  totalWeight: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,12}(?:\.\d{0,3})?$
   */
  totalVolume: string;
}

export interface OrderTask {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  dueDate: string;
  /** @maxLength 512 */
  description: string;
  readonly overdue: boolean;
  /** @format date-time */
  completedDate?: string | null;
}

export interface OrderTotalNetDetailed {
  /** @format uuid */
  readonly id: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly salesNetInBaseCurrency: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly purchasesNetInBaseCurrency: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly totalNetInBaseCurrency: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly marginInPercents: string;
}

export interface OrderTotalNetList {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 255 */
  name: string;
  /**
   * * `TRA` - Transit
   * * `INV` - Invoicing
   * * `TRB` - Transit SPB office
   * * `COF` - Cargo
   * * `INVSPB` - Invoicing SPB Office
   */
  shipmentSerie: ShipmentSerieEnum;
  readonly clientName: string;
  archived?: boolean;
  /**
   * User defined creation date
   * @format date
   */
  creationDate?: string;
  /**
   * Human convenient ref to order
   * @maxLength 127
   */
  orderRef?: string;
  transportationMethods?: TransportationMethodsEnum[];
  readonly destinations: OrderDestination[];
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly salesNetInBaseCurrency: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly purchasesNetInBaseCurrency: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly totalNetInBaseCurrency: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly marginInPercents: string;
}

export interface OrderUpdateRequest {
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * User defined creation date
   * @format date
   */
  creationDate?: string;
  /** @format uuid */
  client: string;
  /**
   * Reference to the client's external system
   * @maxLength 127
   */
  clientRef?: string;
  /** Set or unset order as pinned for the user. Only orders managed by the user could be pinned. */
  pinned: boolean;
  /**
   * * `not-set` - Not set
   * * `FCL` - Full Container Load
   * * `LCL` - Less than Container Load
   */
  loadType?: LoadTypeEnum;
  consignorName?: string;
  consignorAddress?: string;
  consigneeName?: string;
  consigneeAddress?: string;
  termsOfDelivery?: TermsOfDeliveryEnum | BlankEnum;
  /** @maxLength 255 */
  termsOfDeliveryCity?: string;
  securityCheckRequired?: boolean;
  /** @format date */
  securityCheckDate?: string | null;
  insuranceCheckRequired?: boolean;
  /** @format date */
  insuranceCheckDate?: string | null;
  expensiveCargo?: boolean;
  comment?: string;
  destinations: OrderDestinationCreateRequest[];
  transportationMethods?: TransportationMethodsEnum[];
}

export interface Organization {
  /** @format uuid */
  readonly id: string;
  /**
   * Name of legal entity
   * @maxLength 255
   */
  name: string;
  readonly addressInvoiceFormat: string;
  readonly fullAddress: string;
  /**
   * Preferred currency to work with in invoices
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  defaultCurrency?: CurrencyEnum;
}

export interface OrganizationClient {
  /** @format uuid */
  readonly id: string;
  serviceProvider: Organization;
  /**
   * for clients who needs act (certificate of work)
   * @maxLength 1024
   */
  actOfWorkContractDetails?: string;
  /**
   * organization name and full address for act of work paper blank
   * @maxLength 1024
   */
  actOfWorkCustomerTitle?: string;
  /**
   * VAT class for the invoice, in percent.
   *
   * * `0` - Transport of goods to 3rd countries
   * @min -2147483648
   * @max 2147483647
   */
  defaultTaxClass?: TaxClassEnum;
  /**
   * Days amount for payment.
   *
   * * `14` - 14 days netto
   * * `30` - 30 days netto
   * @min -2147483648
   * @max 2147483647
   */
  defaultTermsOfPayment?: TermsOfPaymentEnum;
}

export interface OrganizationClientUpdateRequest {
  /** @format uuid */
  serviceProvider: string;
  /**
   * for clients who needs act (certificate of work)
   * @maxLength 1024
   */
  actOfWorkContractDetails?: string;
  /**
   * organization name and full address for act of work paper blank
   * @maxLength 1024
   */
  actOfWorkCustomerTitle?: string;
  /**
   * VAT class for the invoice, in percent.
   *
   * * `0` - Transport of goods to 3rd countries
   * @min -2147483648
   * @max 2147483647
   */
  defaultTaxClass?: TaxClassEnum;
  /**
   * Days amount for payment.
   *
   * * `14` - 14 days netto
   * * `30` - 30 days netto
   * @min -2147483648
   * @max 2147483647
   */
  defaultTermsOfPayment?: TermsOfPaymentEnum;
}

export interface OrganizationCreateRequest {
  /**
   * Name of legal entity
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Name extension of legal entity
   * @maxLength 255
   */
  nameExt?: string;
  /**
   * Short code for quick selection
   * @maxLength 10
   */
  code?: string;
  /**
   * Business ID of legal entity
   * @maxLength 255
   */
  businessId?: string;
  /**
   * ISO 3166-1 alpha-2 country code
   *
   * * `AF` - Afghanistan
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Caribbean Netherlands
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei Darussalam
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CD` - Congo
   * * `CG` - Congo
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curacao
   * * `CY` - Cyprus
   * * `CZ` - Czech Republic
   * * `CI` - Cote d'Ivoire
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KP` - North Korea
   * * `KR` - South Korea
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `MK` - Republic of North Macedonia
   * * `RO` - Romania
   * * `RU` - Russian Federation
   * * `RW` - Rwanda
   * * `RE` - Reunion
   * * `BL` - Saint Barthelemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - Sandwich Islands
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syrian Arab Republic
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - East Timor
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Turkey
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - Minor Outlying Islands (USA)
   * * `US` - United States
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (UK)
   * * `VI` - Virgin Islands (USA)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   * * `AX` - Aland Islands
   */
  countryCode?: CountryEnum | BlankEnum;
  /**
   * City of legal entity
   * @maxLength 255
   */
  city?: string;
  /**
   * Street address of legal entity
   * @maxLength 255
   */
  streetAddress?: string;
  /**
   * Street address extension of legal entity
   * @maxLength 255
   */
  streetAddressExt?: string;
  /**
   * Postal code of legal entity
   * @maxLength 255
   */
  postalCode?: string;
  /**
   * Bank name of legal entity
   * @maxLength 255
   */
  bankName?: string;
  /**
   * Bank address of legal entity
   * @maxLength 512
   */
  bankAddress?: string;
  /**
   * Bank city of legal entity
   * @maxLength 255
   */
  bankCity?: string;
  /**
   * Bank SWIFT code of legal entity
   * @maxLength 255
   */
  bankSwiftCode?: string;
  /**
   * Bank account number of legal entity
   * @maxLength 255
   */
  bankAccountNumber?: string;
  /**
   * Preferred currency to work with in invoices
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  defaultCurrency?: CurrencyEnum;
  /**
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @maxLength 255 */
  phone?: string;
  /**
   * @format uri
   * @maxLength 200
   */
  website?: string;
  /**
   * Contact person of legal entity
   * @maxLength 255
   */
  contactPerson?: string;
  /** @minLength 1 */
  actOfWorkContractDetails?: string;
  /** @minLength 1 */
  actOfWorkCustomerTitle?: string;
  /** @format uuid */
  serviceProvider?: string;
  /** * `0` - Transport of goods to 3rd countries */
  defaultTaxClass?: TaxClassEnum;
  /**
   * * `14` - 14 days netto
   * * `30` - 30 days netto
   */
  defaultTermsOfPayment?: TermsOfPaymentEnum;
}

export interface OrganizationDetailed {
  /** @format uuid */
  readonly id: string;
  /**
   * Name of legal entity
   * @maxLength 255
   */
  name: string;
  /**
   * Name extension of legal entity
   * @maxLength 255
   */
  nameExt?: string;
  /**
   * Short code for quick selection
   * @maxLength 10
   */
  code?: string;
  /**
   * Business ID of legal entity
   * @maxLength 255
   */
  businessId?: string;
  /**
   * ISO 3166-1 alpha-2 country code
   *
   * * `AF` - Afghanistan
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Caribbean Netherlands
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei Darussalam
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CD` - Congo
   * * `CG` - Congo
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curacao
   * * `CY` - Cyprus
   * * `CZ` - Czech Republic
   * * `CI` - Cote d'Ivoire
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KP` - North Korea
   * * `KR` - South Korea
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `MK` - Republic of North Macedonia
   * * `RO` - Romania
   * * `RU` - Russian Federation
   * * `RW` - Rwanda
   * * `RE` - Reunion
   * * `BL` - Saint Barthelemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - Sandwich Islands
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syrian Arab Republic
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - East Timor
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Turkey
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - Minor Outlying Islands (USA)
   * * `US` - United States
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (UK)
   * * `VI` - Virgin Islands (USA)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   * * `AX` - Aland Islands
   */
  countryCode?: CountryEnum | BlankEnum;
  /**
   * City of legal entity
   * @maxLength 255
   */
  city?: string;
  /**
   * Street address of legal entity
   * @maxLength 255
   */
  streetAddress?: string;
  /**
   * Street address extension of legal entity
   * @maxLength 255
   */
  streetAddressExt?: string;
  /**
   * Postal code of legal entity
   * @maxLength 255
   */
  postalCode?: string;
  /**
   * Bank name of legal entity
   * @maxLength 255
   */
  bankName?: string;
  /**
   * Bank address of legal entity
   * @maxLength 512
   */
  bankAddress?: string;
  /**
   * Bank city of legal entity
   * @maxLength 255
   */
  bankCity?: string;
  /**
   * Bank SWIFT code of legal entity
   * @maxLength 255
   */
  bankSwiftCode?: string;
  /**
   * Bank account number of legal entity
   * @maxLength 255
   */
  bankAccountNumber?: string;
  /**
   * Preferred currency to work with in invoices
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  defaultCurrency?: CurrencyEnum;
  /**
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @maxLength 255 */
  phone?: string;
  /**
   * @format uri
   * @maxLength 200
   */
  website?: string;
  /**
   * Contact person of legal entity
   * @maxLength 255
   */
  contactPerson?: string;
  /** @format uuid */
  readonly client: string;
  /** @format uuid */
  readonly agent: string;
}

export interface OrganizationDetailedRequest {
  /**
   * Name of legal entity
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Name extension of legal entity
   * @maxLength 255
   */
  nameExt?: string;
  /**
   * Short code for quick selection
   * @maxLength 10
   */
  code?: string;
  /**
   * Business ID of legal entity
   * @maxLength 255
   */
  businessId?: string;
  /**
   * ISO 3166-1 alpha-2 country code
   *
   * * `AF` - Afghanistan
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Caribbean Netherlands
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei Darussalam
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CD` - Congo
   * * `CG` - Congo
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curacao
   * * `CY` - Cyprus
   * * `CZ` - Czech Republic
   * * `CI` - Cote d'Ivoire
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KP` - North Korea
   * * `KR` - South Korea
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `MK` - Republic of North Macedonia
   * * `RO` - Romania
   * * `RU` - Russian Federation
   * * `RW` - Rwanda
   * * `RE` - Reunion
   * * `BL` - Saint Barthelemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - Sandwich Islands
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syrian Arab Republic
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - East Timor
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Turkey
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - Minor Outlying Islands (USA)
   * * `US` - United States
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (UK)
   * * `VI` - Virgin Islands (USA)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   * * `AX` - Aland Islands
   */
  countryCode?: CountryEnum | BlankEnum;
  /**
   * City of legal entity
   * @maxLength 255
   */
  city?: string;
  /**
   * Street address of legal entity
   * @maxLength 255
   */
  streetAddress?: string;
  /**
   * Street address extension of legal entity
   * @maxLength 255
   */
  streetAddressExt?: string;
  /**
   * Postal code of legal entity
   * @maxLength 255
   */
  postalCode?: string;
  /**
   * Bank name of legal entity
   * @maxLength 255
   */
  bankName?: string;
  /**
   * Bank address of legal entity
   * @maxLength 512
   */
  bankAddress?: string;
  /**
   * Bank city of legal entity
   * @maxLength 255
   */
  bankCity?: string;
  /**
   * Bank SWIFT code of legal entity
   * @maxLength 255
   */
  bankSwiftCode?: string;
  /**
   * Bank account number of legal entity
   * @maxLength 255
   */
  bankAccountNumber?: string;
  /**
   * Preferred currency to work with in invoices
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  defaultCurrency?: CurrencyEnum;
  /**
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @maxLength 255 */
  phone?: string;
  /**
   * @format uri
   * @maxLength 200
   */
  website?: string;
  /**
   * Contact person of legal entity
   * @maxLength 255
   */
  contactPerson?: string;
}

export interface OrganizationSimple {
  /** @format uuid */
  readonly id: string;
  /**
   * Name of legal entity
   * @maxLength 255
   */
  name: string;
}

export interface PaginatedInvoiceListList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: InvoiceList[];
}

export interface PaginatedOrderListList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: OrderList[];
}

export interface PaginatedOrderTotalNetListList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: OrderTotalNetList[];
}

export interface PatchedChargeCreateRequest {
  /** @format uuid */
  order?: string;
  /** @format uuid */
  service?: string;
  /**
   * Should usually come from the service description but may be overridden
   * @minLength 1
   * @maxLength 255
   */
  description?: string;
  /**
   * @min 1
   * @max 2147483647
   */
  quantity?: number;
  /**
   * The initial price offered for the service. The final price may vary if additional fees are applied.
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  price?: string;
  /**
   * Rate to convert charge currency to base currency.
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,6})?$
   */
  baseCurrencyRate?: string;
  /**
   * Currency of the charge.
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  chargeCurrency?: CurrencyEnum;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  vat?: string;
}

export interface PatchedInsuranceRequest {
  /**
   * * `new` - New
   * * `approved` - Approved
   * * `declined` - Declined
   */
  status?: InsuranceStatusEnum;
  /**
   * @minLength 1
   * @maxLength 127
   */
  number?: string;
  /**
   * The currency of the insurance
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  currency?: CurrencyEnum;
  /**
   * The maximum amount that the insurance company will pay for a covered case
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  coverageAmount?: string;
  /**
   * The amount that the policyholder paid to the insurance company for coverage
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  premium?: string;
  /** @minLength 1 */
  additionalInformation?: string;
}

export interface PatchedOrderAgentCreateRequest {
  /** @format uuid */
  agent?: string;
  /** @format date */
  bidDate?: string | null;
  priceConfirmed?: boolean;
  agentPrice?: string;
  sellingPrice?: string;
}

export interface PatchedOrderShipmentUnitCreateRequest {
  /**
   * Reference to the client's external system
   * @maxLength 127
   */
  clientRef?: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  cargoDescription?: string;
  /**
   * @min 0
   * @max 32767
   */
  quantity?: number;
  /**
   * * `CS` - Cases
   * * `PCS` - Pieces
   * * `CTN` - Carton
   * * `PLL` - Pallets
   * * `PKG` - Packages
   */
  quantityMeasure?: ShipmentUnitQuantityChoice;
  /**
   * Weight measured in kg
   * @format decimal
   * @pattern ^-?\d{0,7}(?:\.\d{0,3})?$
   */
  weight?: string;
  /**
   * Volume measured in m3
   * @format decimal
   * @pattern ^-?\d{0,7}(?:\.\d{0,3})?$
   */
  volume?: string;
}

export interface PatchedOrderUpdateRequest {
  /**
   * @minLength 1
   * @maxLength 255
   */
  name?: string;
  /**
   * User defined creation date
   * @format date
   */
  creationDate?: string;
  /** @format uuid */
  client?: string;
  /**
   * Reference to the client's external system
   * @maxLength 127
   */
  clientRef?: string;
  /** Set or unset order as pinned for the user. Only orders managed by the user could be pinned. */
  pinned?: boolean;
  /**
   * * `not-set` - Not set
   * * `FCL` - Full Container Load
   * * `LCL` - Less than Container Load
   */
  loadType?: LoadTypeEnum;
  consignorName?: string;
  consignorAddress?: string;
  consigneeName?: string;
  consigneeAddress?: string;
  termsOfDelivery?: TermsOfDeliveryEnum | BlankEnum;
  /** @maxLength 255 */
  termsOfDeliveryCity?: string;
  securityCheckRequired?: boolean;
  /** @format date */
  securityCheckDate?: string | null;
  insuranceCheckRequired?: boolean;
  /** @format date */
  insuranceCheckDate?: string | null;
  expensiveCargo?: boolean;
  comment?: string;
  destinations?: OrderDestinationCreateRequest[];
  transportationMethods?: TransportationMethodsEnum[];
}

export interface PatchedOrganizationClientUpdateRequest {
  /** @format uuid */
  serviceProvider?: string;
  /**
   * for clients who needs act (certificate of work)
   * @maxLength 1024
   */
  actOfWorkContractDetails?: string;
  /**
   * organization name and full address for act of work paper blank
   * @maxLength 1024
   */
  actOfWorkCustomerTitle?: string;
  /**
   * VAT class for the invoice, in percent.
   *
   * * `0` - Transport of goods to 3rd countries
   * @min -2147483648
   * @max 2147483647
   */
  defaultTaxClass?: TaxClassEnum;
  /**
   * Days amount for payment.
   *
   * * `14` - 14 days netto
   * * `30` - 30 days netto
   * @min -2147483648
   * @max 2147483647
   */
  defaultTermsOfPayment?: TermsOfPaymentEnum;
}

export interface PatchedOrganizationDetailedRequest {
  /**
   * Name of legal entity
   * @minLength 1
   * @maxLength 255
   */
  name?: string;
  /**
   * Name extension of legal entity
   * @maxLength 255
   */
  nameExt?: string;
  /**
   * Short code for quick selection
   * @maxLength 10
   */
  code?: string;
  /**
   * Business ID of legal entity
   * @maxLength 255
   */
  businessId?: string;
  /**
   * ISO 3166-1 alpha-2 country code
   *
   * * `AF` - Afghanistan
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Caribbean Netherlands
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei Darussalam
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CD` - Congo
   * * `CG` - Congo
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curacao
   * * `CY` - Cyprus
   * * `CZ` - Czech Republic
   * * `CI` - Cote d'Ivoire
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KP` - North Korea
   * * `KR` - South Korea
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `MK` - Republic of North Macedonia
   * * `RO` - Romania
   * * `RU` - Russian Federation
   * * `RW` - Rwanda
   * * `RE` - Reunion
   * * `BL` - Saint Barthelemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - Sandwich Islands
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syrian Arab Republic
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - East Timor
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Turkey
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - Minor Outlying Islands (USA)
   * * `US` - United States
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (UK)
   * * `VI` - Virgin Islands (USA)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   * * `AX` - Aland Islands
   */
  countryCode?: CountryEnum | BlankEnum;
  /**
   * City of legal entity
   * @maxLength 255
   */
  city?: string;
  /**
   * Street address of legal entity
   * @maxLength 255
   */
  streetAddress?: string;
  /**
   * Street address extension of legal entity
   * @maxLength 255
   */
  streetAddressExt?: string;
  /**
   * Postal code of legal entity
   * @maxLength 255
   */
  postalCode?: string;
  /**
   * Bank name of legal entity
   * @maxLength 255
   */
  bankName?: string;
  /**
   * Bank address of legal entity
   * @maxLength 512
   */
  bankAddress?: string;
  /**
   * Bank city of legal entity
   * @maxLength 255
   */
  bankCity?: string;
  /**
   * Bank SWIFT code of legal entity
   * @maxLength 255
   */
  bankSwiftCode?: string;
  /**
   * Bank account number of legal entity
   * @maxLength 255
   */
  bankAccountNumber?: string;
  /**
   * Preferred currency to work with in invoices
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  defaultCurrency?: CurrencyEnum;
  /**
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @maxLength 255 */
  phone?: string;
  /**
   * @format uri
   * @maxLength 200
   */
  website?: string;
  /**
   * Contact person of legal entity
   * @maxLength 255
   */
  contactPerson?: string;
}

export interface PatchedPurchaseInvoiceUpdateRequest {
  /**
   * Date of the billing calculation and currency convertation.
   * @format date
   */
  invoicingDate?: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  number?: string;
  /** @format uuid */
  company?: string;
  /**
   * Currency of total sum in invoice.
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  invoiceCurrency?: CurrencyEnum;
  /**
   * Rate to convert invoice currency to base currency.
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,6})?$
   */
  baseCurrencyRate?: string;
  /**
   * * `debit` - Debit
   * * `credit` - Credit
   */
  accountingType?: AccountingTypeEnum;
}

export interface PatchedSaleInvoiceBlankUpdateRequest {
  /** @maxLength 256 */
  orderRef?: string;
  /** @maxLength 256 */
  clientRef?: string;
  /** @maxLength 128 */
  fromCountry?: string;
  /** @maxLength 128 */
  toCountry?: string;
  /** @maxLength 128 */
  consignorName?: string;
  /** @maxLength 512 */
  consignorAddress?: string;
  /** @maxLength 128 */
  consigneeName?: string;
  /** @maxLength 512 */
  consigneeAddress?: string;
  /** @maxLength 128 */
  termsOfDelivery?: string;
  shipmentUnits?: SaleInvoiceBlankShipmentUnitRequest[];
}

export interface PatchedSaleInvoiceUpdateRequest {
  /** @format uuid */
  payer?: string;
  /**
   * Date of the billing calculation and currency convertation.
   * @format date
   */
  invoicingDate?: string;
  /**
   * Date when the invoice should be paid.
   * @format date
   */
  dueDate?: string | null;
  /**
   * Days amount for payment.
   *
   * * `14` - 14 days netto
   * * `30` - 30 days netto
   * @min -2147483648
   * @max 2147483647
   */
  termsOfPayment?: TermsOfPaymentEnum | NullEnum | null;
  /**
   * VAT class for the invoice, in percent.
   *
   * * `0` - Transport of goods to 3rd countries
   * @min -2147483648
   * @max 2147483647
   */
  taxClass?: TaxClassEnum | NullEnum | null;
  information?: SaleInvoiceBlankUpdateRequest;
  /**
   * * `debit` - Debit
   * * `credit` - Credit
   */
  accountingType?: AccountingTypeEnum;
  /**
   * Currency of total sum in invoice.
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  invoiceCurrency?: CurrencyEnum;
  /**
   * Rate to convert invoice currency to base currency.
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,6})?$
   */
  baseCurrencyRate?: string;
}

export interface PatchedTaskCreateRequest {
  /** @format date-time */
  dueDate?: string;
  /**
   * @minLength 1
   * @maxLength 512
   */
  description?: string;
  /** @format date-time */
  completedDate?: string | null;
}

export interface PatchedUserUpdateRequest {
  /** @format uuid */
  departmentId?: string | null;
  /** @maxLength 255 */
  jobTitle?: string;
}

export interface PurchaseInvoiceCreateRequest {
  /**
   * Date of the billing calculation and currency convertation.
   * @format date
   */
  invoicingDate: string;
  /** @maxLength 50 */
  number: string;
  /** @format uuid */
  company: string;
  /**
   * Currency of total sum in invoice.
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  invoiceCurrency?: CurrencyEnum;
  /**
   * Rate to convert invoice currency to base currency.
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,6})?$
   */
  baseCurrencyRate?: string;
}

export interface PurchaseInvoiceDetailed {
  /** @format uuid */
  readonly id: string;
  /**
   * Type of invoice: sale or purchase.
   *
   * * `sale` - Sale
   * * `purchase` - Purchase
   */
  invoiceType: InvoiceTypeEnum;
  /** @maxLength 50 */
  number: string;
  /**
   * * `open` - Open
   * * `closed` - Closed
   */
  status?: InvoiceStatusEnum;
  /**
   * The basis order for which invoice is related (is created from).
   * @format uuid
   */
  order: string;
  readonly orderRef: string;
  /**
   * Date of the billing calculation and currency convertation.
   * @format date
   */
  invoicingDate: string;
  company: Organization;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly chargesTotalSumInInvoiceCurrency: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly chargesOverallTotalSumInInvoiceCurrency: string;
  /**
   * Currency of total sum in invoice.
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  invoiceCurrency?: CurrencyEnum;
  /**
   * Rate to convert invoice currency to base currency.
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,6})?$
   */
  baseCurrencyRate?: string;
  readonly invoiceDocument: InvoiceDocument | null;
  transaction: TransactionInPurchaseInvoice;
}

export interface PurchaseInvoiceUpdateRequest {
  /**
   * Date of the billing calculation and currency convertation.
   * @format date
   */
  invoicingDate: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  number: string;
  /** @format uuid */
  company: string;
  /**
   * Currency of total sum in invoice.
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  invoiceCurrency?: CurrencyEnum;
  /**
   * Rate to convert invoice currency to base currency.
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,6})?$
   */
  baseCurrencyRate?: string;
  /**
   * * `debit` - Debit
   * * `credit` - Credit
   */
  accountingType: AccountingTypeEnum;
}

export interface RailwayBillOfLadingData {
  /** @maxLength 63 */
  rwb: string;
}

export interface RailwayBillOfLadingDataRequest {
  /** @maxLength 63 */
  rwb: string;
}

/**
 * * `CONTAINER` - Container
 * * `VEHICLE` - Vehicle
 * * `BILL-OF-LADING` - Bill of Lading
 * * `MAWB` - Master AWB
 * * `HAWB` - House AWB
 * * `CMR` - CMR
 * * `RWB` - Railway bill
 */
export enum ReferenceTypeEnum {
  CONTAINER = 'CONTAINER',
  VEHICLE = 'VEHICLE',
  BILLOFLADING = 'BILL-OF-LADING',
  MAWB = 'MAWB',
  HAWB = 'HAWB',
  CMR = 'CMR',
  RWB = 'RWB',
}

/** Serializer used for refreshing JWTs. */
export interface RefreshAuthToken {
  token: string;
}

/** Serializer used for refreshing JWTs. */
export interface RefreshAuthTokenRequest {
  /** @minLength 1 */
  token: string;
}

export interface SaleInvoiceActOfWork {
  services: ActServiceData[];
  readonly actNumber: string;
  readonly actDate: string;
  readonly performer: string;
  readonly customer: string;
  readonly actBasis: string;
  readonly totalNet: string;
  readonly totalNetCurrency: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly vat: string;
  readonly totalNetInWords: string;
}

export interface SaleInvoiceBlank {
  /** @maxLength 256 */
  orderRef?: string;
  /** @maxLength 256 */
  clientRef?: string;
  /** @maxLength 128 */
  fromCountry?: string;
  /** @maxLength 128 */
  toCountry?: string;
  /** @maxLength 128 */
  consignorName?: string;
  /** @maxLength 512 */
  consignorAddress?: string;
  /** @maxLength 128 */
  consigneeName?: string;
  /** @maxLength 512 */
  consigneeAddress?: string;
  /** @maxLength 128 */
  termsOfDelivery?: string;
  readonly shipmentUnits: SaleInvoiceBlankShipmentUnit[];
}

export interface SaleInvoiceBlankResetFieldRequest {
  /**
   * * `orderRef` - orderRef
   * * `clientRef` - clientRef
   * * `fromCountry` - fromCountry
   * * `toCountry` - toCountry
   * * `consignorName` - consignorName
   * * `consignorAddress` - consignorAddress
   * * `consigneeName` - consigneeName
   * * `consigneeAddress` - consigneeAddress
   * * `termsOfDelivery` - termsOfDelivery
   * * `shipmentUnits` - shipmentUnits
   */
  blankField: BlankFieldEnum;
}

export interface SaleInvoiceBlankShipmentReference {
  /**
   * * `CONTAINER` - Container
   * * `VEHICLE` - Vehicle
   * * `BILL-OF-LADING` - Bill of Lading
   * * `MAWB` - Master AWB
   * * `HAWB` - House AWB
   * * `CMR` - CMR
   * * `RWB` - Railway bill
   */
  referenceType: ReferenceTypeEnum;
  referenceData: OrderShipmentReferenceData;
}

export interface SaleInvoiceBlankShipmentReferenceRequest {
  /**
   * * `CONTAINER` - Container
   * * `VEHICLE` - Vehicle
   * * `BILL-OF-LADING` - Bill of Lading
   * * `MAWB` - Master AWB
   * * `HAWB` - House AWB
   * * `CMR` - CMR
   * * `RWB` - Railway bill
   */
  referenceType: ReferenceTypeEnum;
  referenceData: OrderShipmentReferenceDataRequest;
}

/** Shipment units data to use in PDF. */
export interface SaleInvoiceBlankShipmentUnit {
  /** @default "" */
  clientRef?: string;
  cargoDescription: string;
  quantity: string;
  quantityMeasure: string;
  weight: string;
  volume: string;
  shipmentReferences: SaleInvoiceBlankShipmentReference[];
}

/** Shipment units data to use in PDF. */
export interface SaleInvoiceBlankShipmentUnitRequest {
  /** @default "" */
  clientRef?: string;
  cargoDescription: string;
  quantity: string;
  quantityMeasure: string;
  weight: string;
  volume: string;
  shipmentReferences: SaleInvoiceBlankShipmentReferenceRequest[];
}

export interface SaleInvoiceBlankUpdateRequest {
  /** @maxLength 256 */
  orderRef?: string;
  /** @maxLength 256 */
  clientRef?: string;
  /** @maxLength 128 */
  fromCountry?: string;
  /** @maxLength 128 */
  toCountry?: string;
  /** @maxLength 128 */
  consignorName?: string;
  /** @maxLength 512 */
  consignorAddress?: string;
  /** @maxLength 128 */
  consigneeName?: string;
  /** @maxLength 512 */
  consigneeAddress?: string;
  /** @maxLength 128 */
  termsOfDelivery?: string;
  shipmentUnits: SaleInvoiceBlankShipmentUnitRequest[];
}

export interface SaleInvoiceCloseResponse {
  ok: boolean;
}

export interface SaleInvoiceCreateRequest {
  /**
   * Date of the billing calculation and currency convertation.
   * @format date
   */
  invoicingDate: string;
  /** @format uuid */
  payer: string;
  /**
   * Currency of total sum in invoice.
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  invoiceCurrency?: CurrencyEnum;
  /**
   * Rate to convert invoice currency to base currency.
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,6})?$
   */
  baseCurrencyRate?: string;
}

export interface SaleInvoiceDetailed {
  /** @format uuid */
  readonly id: string;
  /**
   * The basis order for which invoice is related (is created from).
   * @format uuid
   */
  order: string;
  /**
   * Type of invoice: sale or purchase.
   *
   * * `sale` - Sale
   * * `purchase` - Purchase
   */
  invoiceType: InvoiceTypeEnum;
  readonly invoicePaperTitle: string;
  /** @maxLength 50 */
  number: string;
  /**
   * * `open` - Open
   * * `closed` - Closed
   */
  status?: InvoiceStatusEnum;
  readonly orderRef: string;
  readonly clientRef: string;
  /**
   * Date of the billing calculation and currency convertation.
   * @format date
   */
  invoicingDate: string;
  /**
   * VAT class for the invoice, in percent.
   *
   * * `0` - Transport of goods to 3rd countries
   * @min -2147483648
   * @max 2147483647
   */
  taxClass?: TaxClassEnum | NullEnum | null;
  readonly taxClassDisplayName: string;
  /**
   * Days amount for payment.
   *
   * * `14` - 14 days netto
   * * `30` - 30 days netto
   * @min -2147483648
   * @max 2147483647
   */
  termsOfPayment?: TermsOfPaymentEnum | NullEnum | null;
  readonly termsOfPaymentDisplayName: string;
  /**
   * Date when the invoice should be paid.
   * @format date
   */
  dueDate?: string | null;
  readonly notifyPeriod: NotifyPeriodEnum;
  readonly penaltyInterest: number;
  information: SaleInvoiceBlank;
  transaction: TransactionInSaleInvoice;
  /**
   * Currency of total sum in invoice.
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  invoiceCurrency?: CurrencyEnum;
  /**
   * Rate to convert invoice currency to base currency.
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,6})?$
   */
  baseCurrencyRate?: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly chargesTotalSumInInvoiceCurrency: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly chargesOverallTotalSumInInvoiceCurrency: string;
  readonly customerBusinessId: string;
  readonly customerNumber: string;
  readonly customerAddress: string;
  readonly customerName: string;
  readonly companyBankDetails: Record<string, any>;
  readonly companyBusinessId: string;
  readonly companyAddress: string;
  readonly companyName: string;
  readonly invoiceDocument: InvoiceDocument | null;
  readonly isActRequired: boolean;
  readonly shipmentUnits: SaleInvoiceShipmentUnitDetailed[];
}

export interface SaleInvoiceOpenResponse {
  ok: boolean;
}

export interface SaleInvoiceSetBlankRequest {
  fieldName: FieldNameEnum | NullEnum | null;
}

export interface SaleInvoiceSetClosedRequest {
  /** @format uuid */
  invoiceDocument?: string;
}

export interface SaleInvoiceShipmentUnitCreate {
  /** @format uuid */
  shipmentUnit: string;
}

export interface SaleInvoiceShipmentUnitCreateRequest {
  /** @format uuid */
  shipmentUnit: string;
}

export interface SaleInvoiceShipmentUnitDetailed {
  /** @format uuid */
  readonly id: string;
  readonly clientRef: string;
  readonly cargoDescription: string;
  readonly quantity: number;
  readonly quantityMeasure: SaleInvoiceShipmentUnitDetailedQuantityMeasureEnum;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly weight: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly volume: string;
  readonly shipmentReferences: OrderShipmentReference[];
}

/**
 * * `CS` - CS
 * * `PCS` - PCS
 * * `CTN` - CTN
 * * `PLL` - PLL
 * * `PKG` - PKG
 */
export enum SaleInvoiceShipmentUnitDetailedQuantityMeasureEnum {
  CS = 'CS',
  PCS = 'PCS',
  CTN = 'CTN',
  PLL = 'PLL',
  PKG = 'PKG',
}

export interface SaleInvoiceUpdateRequest {
  /** @format uuid */
  payer: string;
  /**
   * Date of the billing calculation and currency convertation.
   * @format date
   */
  invoicingDate: string;
  /**
   * Date when the invoice should be paid.
   * @format date
   */
  dueDate?: string | null;
  /**
   * Days amount for payment.
   *
   * * `14` - 14 days netto
   * * `30` - 30 days netto
   * @min -2147483648
   * @max 2147483647
   */
  termsOfPayment?: TermsOfPaymentEnum | NullEnum | null;
  /**
   * VAT class for the invoice, in percent.
   *
   * * `0` - Transport of goods to 3rd countries
   * @min -2147483648
   * @max 2147483647
   */
  taxClass?: TaxClassEnum | NullEnum | null;
  information?: SaleInvoiceBlankUpdateRequest;
  /**
   * * `debit` - Debit
   * * `credit` - Credit
   */
  accountingType: AccountingTypeEnum;
  /**
   * Currency of total sum in invoice.
   *
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   */
  invoiceCurrency?: CurrencyEnum;
  /**
   * Rate to convert invoice currency to base currency.
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,6})?$
   */
  baseCurrencyRate?: string;
}

export interface Service {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 10 */
  code: string;
  /** @maxLength 255 */
  description: string;
}

/**
 * * `TRA` - Transit
 * * `INV` - Invoicing
 * * `TRB` - Transit SPB office
 * * `COF` - Cargo
 * * `INVSPB` - Invoicing SPB Office
 */
export enum ShipmentSerieEnum {
  COF = 'COF',
  INV = 'INV',
  INVSPB = 'INVSPB',
  TRA = 'TRA',
  TRB = 'TRB',
}

/**
 * * `CS` - Cases
 * * `PCS` - Pieces
 * * `CTN` - Carton
 * * `PLL` - Pallets
 * * `PKG` - Packages
 */
export enum ShipmentUnitQuantityChoice {
  CS = 'CS',
  PCS = 'PCS',
  CTN = 'CTN',
  PLL = 'PLL',
  PKG = 'PKG',
}

export interface SiteNotification {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created: string;
  /** Rendered body of the notification. */
  readonly renderedBody: string;
  /**
   * * `new` - new
   * * `viewed` - viewed
   */
  reaction: SiteNotificationReactionEnum;
  notificationContext: SiteNotificationContext;
}

export interface SiteNotificationContext {
  /**
   * * `order` - order
   * * `task` - task
   */
  notificationType: NotificationTypeEnum;
  context: MetaSiteNotificationContext;
}

export interface SiteNotificationCount {
  countNew: number;
}

export interface SiteNotificationMarkAllViewed {
  ok: boolean;
}

/**
 * * `new` - new
 * * `viewed` - viewed
 */
export enum SiteNotificationReactionEnum {
  New = 'new',
  Viewed = 'viewed',
}

export interface SiteNotificationSnoozeRequest {
  /**
   * The date to reschedule the notification. Should be in the future.
   * @format date-time
   */
  dateToSchedule: string;
}

export interface SiteNotificationSnoozeResponse {
  ok: boolean;
}

/** * `viewed` - viewed */
export enum SiteNotificationUpdateReactionEnum {
  Viewed = 'viewed',
}

export interface SiteNotificationUpdateRequest {
  /** * `viewed` - viewed */
  reaction: SiteNotificationUpdateReactionEnum;
}

export interface Task {
  /** @format uuid */
  readonly id: string;
  order: OrderInTask;
  /** @format date-time */
  dueDate: string;
  /** @maxLength 512 */
  description: string;
}

/** TaskContextDto */
export interface TaskContextDto {
  /**
   * Order Id
   * @format uuid
   */
  orderId: string;
  /** Order Ref */
  orderRef: string;
  /** Order Name */
  orderName: string;
  /**
   * Task Id
   * @format uuid
   */
  taskId: string;
  /** Task Description */
  taskDescription: string;
}

export interface TaskCreateRequest {
  /** @format date-time */
  dueDate: string;
  /**
   * @minLength 1
   * @maxLength 512
   */
  description: string;
  /** @format date-time */
  completedDate?: string | null;
}

/** * `0` - Transport of goods to 3rd countries */
export enum TaxClassEnum {
  Value0 = 0,
}

/**
 * * `CFR` - Cost and Freight
 * * `CIF` - Cost, Insurance and Freight
 * * `CIP` - Carriage and Insurance Paid to
 * * `CPT` - Carriage Paid to
 * * `DAF` - Delivered at Frontier
 * * `DAP` - Delivered at Place
 * * `DAT` - Delivered at Terminal
 * * `DDP` - Delivered Duty Paid
 * * `DDU` - Delivered Duty Unpaid
 * * `DEQ` - Delivered Ex Quay
 * * `DES` - Delivered Ex Ship
 * * `DPU` - Delivered at Place Unloaded
 * * `EXW` - Ex Works
 * * `FAS` - Free Alongside Ship
 * * `FCA` - Free Carrier
 * * `FOB` - Free on Board
 * * `XXX` - Other delivery terms
 */
export enum TermsOfDeliveryEnum {
  CFR = 'CFR',
  CIF = 'CIF',
  CIP = 'CIP',
  CPT = 'CPT',
  DAF = 'DAF',
  DAP = 'DAP',
  DAT = 'DAT',
  DDP = 'DDP',
  DDU = 'DDU',
  DEQ = 'DEQ',
  DES = 'DES',
  DPU = 'DPU',
  EXW = 'EXW',
  FAS = 'FAS',
  FCA = 'FCA',
  FOB = 'FOB',
  XXX = 'XXX',
}

/**
 * * `14` - 14 days netto
 * * `30` - 30 days netto
 */
export enum TermsOfPaymentEnum {
  Value14 = 14,
  Value30 = 30,
}

export interface TransactionInPurchaseInvoice {
  /** @format uuid */
  readonly id: string;
  /**
   * * `debit` - Debit
   * * `credit` - Credit
   */
  accountingType: AccountingTypeEnum;
  charges: Charge[];
  /** @format uuid */
  payer: string;
}

export interface TransactionInSaleInvoice {
  /** @format uuid */
  readonly id: string;
  readonly isSecCheckEnabled: boolean;
  /**
   * * `debit` - Debit
   * * `credit` - Credit
   */
  accountingType: AccountingTypeEnum;
  charges: Charge[];
  /** @format uuid */
  payer: string;
}

/**
 * * `avia` - Avia
 * * `sea` - Sea
 * * `track` - Track
 * * `rail` - Rail
 */
export enum TransportationMethodsEnum {
  Avia = 'avia',
  Sea = 'sea',
  Track = 'track',
  Rail = 'rail',
}

export interface User {
  /** @format uuid */
  readonly id: string;
  /**
   * Required. 150 characters or fewer. English letters, digits and @/./+/-/_ only.
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username: string;
  /** @maxLength 255 */
  jobTitle?: string;
  /** @maxLength 150 */
  firstName?: string;
  /** @maxLength 150 */
  lastName?: string;
}

export interface UserDetailed {
  /** @format uuid */
  readonly id: string;
  /**
   * Required. 150 characters or fewer. English letters, digits and @/./+/-/_ only.
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username: string;
  /** @maxLength 150 */
  firstName?: string;
  /** @maxLength 150 */
  lastName?: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @maxLength 255 */
  jobTitle?: string;
  readonly department: Department;
  /** Whether the user has configured their profile */
  userConfigured?: boolean;
  readonly remoteAddr: string;
}

export interface UserSessionControlStatus {
  isEnabled: boolean;
}

export interface UserSimple {
  /** @format uuid */
  readonly id: string;
  /**
   * Required. 150 characters or fewer. English letters, digits and @/./+/-/_ only.
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username: string;
  /** @maxLength 150 */
  firstName?: string;
  /** @maxLength 150 */
  lastName?: string;
}

export interface UserUpdateRequest {
  /** @format uuid */
  departmentId: string | null;
  /** @maxLength 255 */
  jobTitle?: string;
}

export interface VehicleData {
  /** @maxLength 63 */
  vehicleId: string;
  /** @maxLength 63 */
  trailerId: string;
}

export interface VehicleDataRequest {
  /** @maxLength 63 */
  vehicleId: string;
  /** @maxLength 63 */
  trailerId: string;
}

export interface AgentsListParams {
  search?: string;
}

export interface ClientsListParams {
  search?: string;
}

export enum CurrencyRatesRetrieveTypeEnum {
  ServiceError = 'ServiceError',
}

export enum CurrencyRatesRetrieveCodeEnum {
  CurrencyBaseNotFound = 'currencyBaseNotFound',
  IntegrationError = 'integrationError',
}

export interface CurrencyRatesRetrieveParams {
  /**
   * * `AED` - United Arab Emirates Dirham
   * * `AUD` - Australian Dollar
   * * `CHF` - Swiss Franc
   * * `CNY` - Chinese Yuan
   * * `EUR` - Euro
   * * `GBP` - British Pound Sterling
   * * `TRY` - Turkish Lira
   * * `USD` - United States Dollar
   * * `RUB` - Russian Ruble
   * @minLength 1
   * @default "EUR"
   */
  base?: BaseEnum;
  /** @format date */
  date?: string;
}

/**
 * * `AED` - United Arab Emirates Dirham
 * * `AUD` - Australian Dollar
 * * `CHF` - Swiss Franc
 * * `CNY` - Chinese Yuan
 * * `EUR` - Euro
 * * `GBP` - British Pound Sterling
 * * `TRY` - Turkish Lira
 * * `USD` - United States Dollar
 * * `RUB` - Russian Ruble
 * @minLength 1
 * @default "EUR"
 */
export enum BaseEnum {
  AED = 'AED',
  AUD = 'AUD',
  CHF = 'CHF',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  TRY = 'TRY',
  USD = 'USD',
  RUB = 'RUB',
}

/**
 * * `AED` - United Arab Emirates Dirham
 * * `AUD` - Australian Dollar
 * * `CHF` - Swiss Franc
 * * `CNY` - Chinese Yuan
 * * `EUR` - Euro
 * * `GBP` - British Pound Sterling
 * * `TRY` - Turkish Lira
 * * `USD` - United States Dollar
 * * `RUB` - Russian Ruble
 * @minLength 1
 * @default "EUR"
 */
export enum CurrencyRatesRetrieveParams1BaseEnum {
  AED = 'AED',
  AUD = 'AUD',
  CHF = 'CHF',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  TRY = 'TRY',
  USD = 'USD',
  RUB = 'RUB',
}

export interface DocsSchemaRetrieveParams {
  format?: FormatEnum;
  lang?: LangEnum;
}

export enum FormatEnum {
  Json = 'json',
  Yaml = 'yaml',
}

export enum LangEnum {
  Af = 'af',
  Ar = 'ar',
  ArDz = 'ar-dz',
  Ast = 'ast',
  Az = 'az',
  Be = 'be',
  Bg = 'bg',
  Bn = 'bn',
  Br = 'br',
  Bs = 'bs',
  Ca = 'ca',
  Ckb = 'ckb',
  Cs = 'cs',
  Cy = 'cy',
  Da = 'da',
  De = 'de',
  Dsb = 'dsb',
  El = 'el',
  En = 'en',
  EnAu = 'en-au',
  EnGb = 'en-gb',
  Eo = 'eo',
  Es = 'es',
  EsAr = 'es-ar',
  EsCo = 'es-co',
  EsMx = 'es-mx',
  EsNi = 'es-ni',
  EsVe = 'es-ve',
  Et = 'et',
  Eu = 'eu',
  Fa = 'fa',
  Fi = 'fi',
  Fr = 'fr',
  Fy = 'fy',
  Ga = 'ga',
  Gd = 'gd',
  Gl = 'gl',
  He = 'he',
  Hi = 'hi',
  Hr = 'hr',
  Hsb = 'hsb',
  Hu = 'hu',
  Hy = 'hy',
  Ia = 'ia',
  Id = 'id',
  Ig = 'ig',
  Io = 'io',
  Is = 'is',
  It = 'it',
  Ja = 'ja',
  Ka = 'ka',
  Kab = 'kab',
  Kk = 'kk',
  Km = 'km',
  Kn = 'kn',
  Ko = 'ko',
  Ky = 'ky',
  Lb = 'lb',
  Lt = 'lt',
  Lv = 'lv',
  Mk = 'mk',
  Ml = 'ml',
  Mn = 'mn',
  Mr = 'mr',
  Ms = 'ms',
  My = 'my',
  Nb = 'nb',
  Ne = 'ne',
  Nl = 'nl',
  Nn = 'nn',
  Os = 'os',
  Pa = 'pa',
  Pl = 'pl',
  Pt = 'pt',
  PtBr = 'pt-br',
  Ro = 'ro',
  Ru = 'ru',
  Sk = 'sk',
  Sl = 'sl',
  Sq = 'sq',
  Sr = 'sr',
  SrLatn = 'sr-latn',
  Sv = 'sv',
  Sw = 'sw',
  Ta = 'ta',
  Te = 'te',
  Tg = 'tg',
  Th = 'th',
  Tk = 'tk',
  Tr = 'tr',
  Tt = 'tt',
  Udm = 'udm',
  Uk = 'uk',
  Ur = 'ur',
  Uz = 'uz',
  Vi = 'vi',
  ZhHans = 'zh-hans',
  ZhHant = 'zh-hant',
}

export enum DocsSchemaRetrieveParams1FormatEnum {
  Json = 'json',
  Yaml = 'yaml',
}

export enum DocsSchemaRetrieveParams1LangEnum {
  Af = 'af',
  Ar = 'ar',
  ArDz = 'ar-dz',
  Ast = 'ast',
  Az = 'az',
  Be = 'be',
  Bg = 'bg',
  Bn = 'bn',
  Br = 'br',
  Bs = 'bs',
  Ca = 'ca',
  Ckb = 'ckb',
  Cs = 'cs',
  Cy = 'cy',
  Da = 'da',
  De = 'de',
  Dsb = 'dsb',
  El = 'el',
  En = 'en',
  EnAu = 'en-au',
  EnGb = 'en-gb',
  Eo = 'eo',
  Es = 'es',
  EsAr = 'es-ar',
  EsCo = 'es-co',
  EsMx = 'es-mx',
  EsNi = 'es-ni',
  EsVe = 'es-ve',
  Et = 'et',
  Eu = 'eu',
  Fa = 'fa',
  Fi = 'fi',
  Fr = 'fr',
  Fy = 'fy',
  Ga = 'ga',
  Gd = 'gd',
  Gl = 'gl',
  He = 'he',
  Hi = 'hi',
  Hr = 'hr',
  Hsb = 'hsb',
  Hu = 'hu',
  Hy = 'hy',
  Ia = 'ia',
  Id = 'id',
  Ig = 'ig',
  Io = 'io',
  Is = 'is',
  It = 'it',
  Ja = 'ja',
  Ka = 'ka',
  Kab = 'kab',
  Kk = 'kk',
  Km = 'km',
  Kn = 'kn',
  Ko = 'ko',
  Ky = 'ky',
  Lb = 'lb',
  Lt = 'lt',
  Lv = 'lv',
  Mk = 'mk',
  Ml = 'ml',
  Mn = 'mn',
  Mr = 'mr',
  Ms = 'ms',
  My = 'my',
  Nb = 'nb',
  Ne = 'ne',
  Nl = 'nl',
  Nn = 'nn',
  Os = 'os',
  Pa = 'pa',
  Pl = 'pl',
  Pt = 'pt',
  PtBr = 'pt-br',
  Ro = 'ro',
  Ru = 'ru',
  Sk = 'sk',
  Sl = 'sl',
  Sq = 'sq',
  Sr = 'sr',
  SrLatn = 'sr-latn',
  Sv = 'sv',
  Sw = 'sw',
  Ta = 'ta',
  Te = 'te',
  Tg = 'tg',
  Th = 'th',
  Tk = 'tk',
  Tr = 'tr',
  Tt = 'tt',
  Udm = 'udm',
  Uk = 'uk',
  Ur = 'ur',
  Uz = 'uz',
  Vi = 'vi',
  ZhHans = 'zh-hans',
  ZhHant = 'zh-hant',
}

export interface InvoicesListParams {
  /** Multiple values may be separated by commas. */
  counterparty?: string[];
  /** 'sale' or 'purchase'. */
  invoiceType?: string;
  /** @format date */
  invoicingDateAfter?: string;
  /** @format date */
  invoicingDateBefore?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  page_size?: number;
  /** Search by Number, Client Ref, or Order ref. */
  search?: string;
  /** @format uuid */
  serviceProvider?: string;
  status?: string;
  /** Multiple values may be separated by commas. */
  transportationMethods?: string[];
}

export interface InvoicesCashFlowExportRetrieveParams {
  /** Multiple values may be separated by commas. */
  counterparty?: string[];
  /** 'sale' or 'purchase'. */
  invoiceType?: string;
  /** @format date */
  invoicingDateAfter?: string;
  /** @format date */
  invoicingDateBefore?: string;
  /** Search by Number, Client Ref, or Order ref. */
  search?: string;
  /** @format uuid */
  serviceProvider?: string;
  status?: string;
  /** Multiple values may be separated by commas. */
  transportationMethods?: string[];
}

export interface InvoicesExportRetrieveParams {
  /** Multiple values may be separated by commas. */
  counterparty?: string[];
  /** 'sale' or 'purchase'. */
  invoiceType?: string;
  /** @format date */
  invoicingDateAfter?: string;
  /** @format date */
  invoicingDateBefore?: string;
  /** Search by Number, Client Ref, or Order ref. */
  search?: string;
  /** @format uuid */
  serviceProvider?: string;
  status?: string;
  /** Multiple values may be separated by commas. */
  transportationMethods?: string[];
}

export interface InvoicesTotalNetRetrieveParams {
  /** Multiple values may be separated by commas. */
  counterparty?: string[];
  /** 'sale' or 'purchase'. */
  invoiceType?: string;
  /** @format date */
  invoicingDateAfter?: string;
  /** @format date */
  invoicingDateBefore?: string;
  /** Search by Number, Client Ref, or Order ref. */
  search?: string;
  /** @format uuid */
  serviceProvider?: string;
  status?: string;
  /** Multiple values may be separated by commas. */
  transportationMethods?: string[];
}

export enum NotificationsSnoozeCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum NotificationsSnoozeCreateCodeEnum {
  DateToSchedule = 'dateToSchedule',
  SnoozeFailed = 'snoozeFailed',
}

export interface OrderLabelsListParams {
  search?: string;
}

export interface OrdersListParams {
  /** Multiple values may be separated by commas. */
  agentIds?: string[];
  archived?: boolean;
  /** Multiple values may be separated by commas. */
  clientIds?: string[];
  /** @format date */
  creationDateAfter?: string;
  /** @format date */
  creationDateBefore?: string;
  /** Multiple values may be separated by commas. */
  labelIds?: string[];
  managedByUser?: boolean;
  /** Multiple values may be separated by commas. */
  managedByUserIds?: string[];
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  page_size?: number;
  /** Search by Name, Client Ref, or Order ref. */
  search?: string;
  /**
   * * `TRA` - Transit
   * * `INV` - Invoicing
   * * `TRB` - Transit SPB office
   * * `COF` - Cargo
   * * `INVSPB` - Invoicing SPB Office
   */
  shipmentSerie?: ShipmentSerieEnum;
  /** Multiple values may be separated by commas. */
  transportationMethods?: string[];
}

/**
 * * `TRA` - Transit
 * * `INV` - Invoicing
 * * `TRB` - Transit SPB office
 * * `COF` - Cargo
 * * `INVSPB` - Invoicing SPB Office
 */
export enum OrdersListParams1ShipmentSerieEnum {
  COF = 'COF',
  INV = 'INV',
  INVSPB = 'INVSPB',
  TRA = 'TRA',
  TRB = 'TRB',
}

export enum OrdersCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersCreateCodeEnum {
  ClientRef = 'clientRef',
  Destinations = 'destinations',
  Labels = 'labels',
  Name = 'name',
  NotExpectedField = 'notExpectedField',
  RequiredFieldMissed = 'requiredFieldMissed',
  TransportationMethods = 'transportationMethods',
}

export interface OrdersTotalNetListParams {
  /** Multiple values may be separated by commas. */
  agentIds?: string[];
  archived?: boolean;
  /** Multiple values may be separated by commas. */
  clientIds?: string[];
  /** @format date */
  creationDateAfter?: string;
  /** @format date */
  creationDateBefore?: string;
  /** Multiple values may be separated by commas. */
  labelIds?: string[];
  managedByUser?: boolean;
  /** Multiple values may be separated by commas. */
  managedByUserIds?: string[];
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  page_size?: number;
  /** Search by Name, Client Ref, or Order ref. */
  search?: string;
  /**
   * * `TRA` - Transit
   * * `INV` - Invoicing
   * * `TRB` - Transit SPB office
   * * `COF` - Cargo
   * * `INVSPB` - Invoicing SPB Office
   */
  shipmentSerie?: ShipmentSerieEnum1;
  /** Multiple values may be separated by commas. */
  transportationMethods?: string[];
}

/**
 * * `TRA` - Transit
 * * `INV` - Invoicing
 * * `TRB` - Transit SPB office
 * * `COF` - Cargo
 * * `INVSPB` - Invoicing SPB Office
 */
export enum ShipmentSerieEnum1 {
  COF = 'COF',
  INV = 'INV',
  INVSPB = 'INVSPB',
  TRA = 'TRA',
  TRB = 'TRB',
}

/**
 * * `TRA` - Transit
 * * `INV` - Invoicing
 * * `TRB` - Transit SPB office
 * * `COF` - Cargo
 * * `INVSPB` - Invoicing SPB Office
 */
export enum OrdersTotalNetListParams1ShipmentSerieEnum {
  COF = 'COF',
  INV = 'INV',
  INVSPB = 'INVSPB',
  TRA = 'TRA',
  TRB = 'TRB',
}

export interface OrdersTotalNetExportRetrieveParams {
  /** Multiple values may be separated by commas. */
  agentIds?: string[];
  archived?: boolean;
  /** Multiple values may be separated by commas. */
  clientIds?: string[];
  /** @format date */
  creationDateAfter?: string;
  /** @format date */
  creationDateBefore?: string;
  /** Multiple values may be separated by commas. */
  labelIds?: string[];
  managedByUser?: boolean;
  /** Multiple values may be separated by commas. */
  managedByUserIds?: string[];
  /** Search by Name, Client Ref, or Order ref. */
  search?: string;
  /**
   * * `TRA` - Transit
   * * `INV` - Invoicing
   * * `TRB` - Transit SPB office
   * * `COF` - Cargo
   * * `INVSPB` - Invoicing SPB Office
   */
  shipmentSerie?: ShipmentSerieEnum2;
  /** Multiple values may be separated by commas. */
  transportationMethods?: string[];
}

/**
 * * `TRA` - Transit
 * * `INV` - Invoicing
 * * `TRB` - Transit SPB office
 * * `COF` - Cargo
 * * `INVSPB` - Invoicing SPB Office
 */
export enum ShipmentSerieEnum2 {
  COF = 'COF',
  INV = 'INV',
  INVSPB = 'INVSPB',
  TRA = 'TRA',
  TRB = 'TRB',
}

/**
 * * `TRA` - Transit
 * * `INV` - Invoicing
 * * `TRB` - Transit SPB office
 * * `COF` - Cargo
 * * `INVSPB` - Invoicing SPB Office
 */
export enum OrdersTotalNetExportRetrieveParams1ShipmentSerieEnum {
  COF = 'COF',
  INV = 'INV',
  INVSPB = 'INVSPB',
  TRA = 'TRA',
  TRB = 'TRB',
}

export enum OrdersAgentsCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersAgentsCreateCodeEnum {
  Agent = 'agent',
  OrderUpdateForbidden = 'orderUpdateForbidden',
}

export enum OrdersAgentsUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersAgentsUpdateCodeEnum {
  Agent = 'agent',
  OrderUpdateForbidden = 'orderUpdateForbidden',
}

export enum OrdersAgentsPartialUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersAgentsPartialUpdateCodeEnum {
  Agent = 'agent',
  OrderUpdateForbidden = 'orderUpdateForbidden',
}

export enum OrdersInsurancesDocumentCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInsurancesDocumentCreateCodeEnum {
  Insurance = 'insurance',
}

export enum OrdersInsurancesDocumentDestroyTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInsurancesDocumentDestroyCodeEnum {
  Insurance = 'insurance',
}

export enum OrdersInsurancesUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInsurancesUpdateCodeEnum {
  Insurance = 'insurance',
}

export enum OrdersInsurancesPartialUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInsurancesPartialUpdateCodeEnum {
  Insurance = 'insurance',
}

export enum OrdersInvoicesForPurchaseCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForPurchaseCreateCodeEnum {
  AccountingType = 'accountingType',
  PayerAndReceiverIsEqual = 'payerAndReceiverIsEqual',
  PayerOrReceiverIsNotCargoEntity = 'payerOrReceiverIsNotCargoEntity',
}

export enum OrdersInvoicesForPurchaseDocumentCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForPurchaseDocumentCreateCodeEnum {
  InvoiceDocument = 'invoiceDocument',
  InvoiceUpdateForbidden = 'invoiceUpdateForbidden',
}

export enum OrdersInvoicesForPurchaseDocumentDestroyTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForPurchaseDocumentDestroyCodeEnum {
  InvoiceDocument = 'invoiceDocument',
  InvoiceUpdateForbidden = 'invoiceUpdateForbidden',
}

export enum OrdersInvoicesForPurchaseUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForPurchaseUpdateCodeEnum {
  AccountingType = 'accountingType',
  InvoiceUpdateForbidden = 'invoiceUpdateForbidden',
  PayerAndReceiverIsEqual = 'payerAndReceiverIsEqual',
  PayerOrReceiverIsNotCargoEntity = 'payerOrReceiverIsNotCargoEntity',
  TransactionUpdateForbidden = 'transactionUpdateForbidden',
}

export enum OrdersInvoicesForPurchasePartialUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForPurchasePartialUpdateCodeEnum {
  AccountingType = 'accountingType',
  InvoiceUpdateForbidden = 'invoiceUpdateForbidden',
  PayerAndReceiverIsEqual = 'payerAndReceiverIsEqual',
  PayerOrReceiverIsNotCargoEntity = 'payerOrReceiverIsNotCargoEntity',
  TransactionUpdateForbidden = 'transactionUpdateForbidden',
}

export enum OrdersInvoicesForSaleCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForSaleCreateCodeEnum {
  AccountingType = 'accountingType',
  PayerAndReceiverIsEqual = 'payerAndReceiverIsEqual',
  PayerOrReceiverIsNotCargoEntity = 'payerOrReceiverIsNotCargoEntity',
}

export enum OrdersInvoicesForSaleBlankPartialUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForSaleBlankPartialUpdateCodeEnum {
  InvoiceUpdateForbidden = 'invoiceUpdateForbidden',
  OrderUpdateForbidden = 'orderUpdateForbidden',
}

export enum OrdersInvoicesForSaleBlankResetCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForSaleBlankResetCreateCodeEnum {
  InvoiceUpdateForbidden = 'invoiceUpdateForbidden',
  OrderUpdateForbidden = 'orderUpdateForbidden',
}

export enum OrdersInvoicesForSaleShipmentUnitsCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForSaleShipmentUnitsCreateCodeEnum {
  InvoiceUpdateForbidden = 'invoiceUpdateForbidden',
  OrderUpdateForbidden = 'orderUpdateForbidden',
}

export enum OrdersInvoicesForSaleShipmentUnitsDestroyTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForSaleShipmentUnitsDestroyCodeEnum {
  InvoiceUpdateForbidden = 'invoiceUpdateForbidden',
  OrderUpdateForbidden = 'orderUpdateForbidden',
}

export enum OrdersInvoicesForSaleUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForSaleUpdateCodeEnum {
  AccountingType = 'accountingType',
  InvoiceUpdateForbidden = 'invoiceUpdateForbidden',
  PayerAndReceiverIsEqual = 'payerAndReceiverIsEqual',
  PayerOrReceiverIsNotCargoEntity = 'payerOrReceiverIsNotCargoEntity',
  TransactionUpdateForbidden = 'transactionUpdateForbidden',
}

export enum OrdersInvoicesForSalePartialUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForSalePartialUpdateCodeEnum {
  AccountingType = 'accountingType',
  InvoiceUpdateForbidden = 'invoiceUpdateForbidden',
  PayerAndReceiverIsEqual = 'payerAndReceiverIsEqual',
  PayerOrReceiverIsNotCargoEntity = 'payerOrReceiverIsNotCargoEntity',
  TransactionUpdateForbidden = 'transactionUpdateForbidden',
}

export enum OrdersInvoicesForSaleCloseCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForSaleCloseCreateCodeEnum {
  InvoiceDocument = 'invoiceDocument',
  InvoiceUpdateForbidden = 'invoiceUpdateForbidden',
}

export enum OrdersInvoicesForSaleGeneratePdfCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForSaleGeneratePdfCreateCodeEnum {
  InvoiceUpdateForbidden = 'invoiceUpdateForbidden',
}

export enum OrdersInvoicesForSaleSetOrderInformationCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersInvoicesForSaleSetOrderInformationCreateCodeEnum {
  InvoiceUpdateForbidden = 'invoiceUpdateForbidden',
}

export enum OrdersLabelsUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersLabelsUpdateCodeEnum {
  Labels = 'labels',
  OrderUpdateForbidden = 'orderUpdateForbidden',
}

export enum OrdersManagersUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersManagersUpdateCodeEnum {
  InsufficientManagersCount = 'insufficientManagersCount',
  OrderUpdateForbidden = 'orderUpdateForbidden',
}

export enum OrdersShipmentUnitsDestroyTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersShipmentUnitsDestroyCodeEnum {
  ShipmentUnitDeleteForbidden = 'shipmentUnitDeleteForbidden',
}

export enum OrdersUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersUpdateCodeEnum {
  Archived = 'archived',
  ClientRef = 'clientRef',
  Destinations = 'destinations',
  InsuranceCheckDate = 'insuranceCheckDate',
  InsuranceCheckRequired = 'insuranceCheckRequired',
  Name = 'name',
  NotExpectedField = 'notExpectedField',
  Pinned = 'pinned',
  RequiredFieldMissed = 'requiredFieldMissed',
  SecurityCheckDate = 'securityCheckDate',
  SecurityCheckRequired = 'securityCheckRequired',
  TransportationMethods = 'transportationMethods',
}

export enum OrdersPartialUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersPartialUpdateCodeEnum {
  Archived = 'archived',
  ClientRef = 'clientRef',
  Destinations = 'destinations',
  InsuranceCheckDate = 'insuranceCheckDate',
  InsuranceCheckRequired = 'insuranceCheckRequired',
  Name = 'name',
  NotExpectedField = 'notExpectedField',
  Pinned = 'pinned',
  RequiredFieldMissed = 'requiredFieldMissed',
  SecurityCheckDate = 'securityCheckDate',
  SecurityCheckRequired = 'securityCheckRequired',
  TransportationMethods = 'transportationMethods',
}

export enum OrdersDestroyTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersDestroyCodeEnum {
  DeleteForbidden = 'deleteForbidden',
}

export enum OrdersSetArchivedCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrdersSetArchivedCreateCodeEnum {
  ArchiveForbidden = 'archiveForbidden',
  UnarchiveForbidden = 'unarchiveForbidden',
}

export interface OrganizationsListParams {
  /** Filter (true) or Exclude (false) Cargo entities */
  cargoEntities?: boolean;
  search?: string;
}

export enum OrganizationsCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrganizationsCreateCodeEnum {
  Name = 'name',
}

export enum OrganizationsUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrganizationsUpdateCodeEnum {
  Name = 'name',
}

export enum OrganizationsPartialUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum OrganizationsPartialUpdateCodeEnum {
  Name = 'name',
}

export interface ServicesListParams {
  search?: string;
}

export interface TasksListParams {
  overdue?: boolean;
}

export enum TransactionsChargesCreateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum TransactionsChargesCreateCodeEnum {
  Service = 'service',
  TransactionUpdateForbidden = 'transactionUpdateForbidden',
}

export enum TransactionsChargesUpdateTypeEnum {
  ServiceError = 'ServiceError',
}

export enum TransactionsChargesUpdateCodeEnum {
  ChargeUpdateForbidden = 'chargeUpdateForbidden',
  Service = 'service',
  TransactionUpdateForbidden = 'transactionUpdateForbidden',
}

export interface UsersListParams {
  /** Search by first name or last name */
  search?: string;
}
